import { useContext, useEffect, useState } from 'react';
import { LoadingContainer } from '../../../components/LoadingContainer';
import API from '../../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { TRiskArea } from '../../../models/risk_area';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  UnorderedList,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ContentPageTitle, Label } from '../../../components/Layout';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  EllipsisVerticalIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Guideline } from '../../../models/guideline';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { UsersContext } from '../../../contexts';
import SidebarContext from '../../../contexts/SidebarContext';
import RiskAreaContext from '../../../contexts/RiskAreaContext';
import { CKEditorWrapper } from '../../../components/TextContentEditor/CKEditorWrapper';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { DangerMenuItem } from '../../../components/DangerMenuItem';

type Props = {};

type RiskAreaAccordionItemProps = {
  riskArea: TRiskArea;
  collapsed?: boolean;
  onCollapse: () => void;
  onUpdateRiskArea: () => void;
  onAddGuideline: () => void;
  onDeleteRiskArea: () => void;
  children: React.ReactNode;
};

const RiskAreaAccordionItem = ({
  collapsed,
  riskArea,
  onCollapse,
  onUpdateRiskArea,
  onAddGuideline,
  onDeleteRiskArea,
  children,
}: RiskAreaAccordionItemProps) => {
  const { userHasPermission } = useContext(UsersContext);
  const canAddGuideline = userHasPermission(['add_guideline'], 'all');
  const canUpdateRiskArea = userHasPermission(['update_risk_area'], 'all');
  const canDeleteRiskArea = userHasPermission(['delete_risk_area'], 'all');

  return (
    <>
      <HStack
        bg={useColorModeValue('neutral.50', 'neutral.900')}
        _hover={{
          bg: useColorModeValue('neutral.100', 'neutral.850'),
        }}
        p={1.5}
        pl={4}
        pr={2}
        gap={0}
        role="group"
        data-testid="section-control"
        borderY="1px"
        mt="-1px"
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        onClick={onCollapse}
        justifyContent="space-between"
      >
        <Text>{riskArea.name}</Text>

        <HStack mx={1}>
          {(canUpdateRiskArea || canAddGuideline || canDeleteRiskArea) && (
            <Box onClick={e => e.stopPropagation()}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                  variant="ghost"
                  visibility={'hidden'}
                  _groupHover={{ visibility: 'visible' }}
                  size="sm"
                />
                <MenuList>
                  {canUpdateRiskArea && (
                    <MenuItem
                      icon={<Icon as={PencilIcon} boxSize={5} />}
                      onClick={onUpdateRiskArea}
                    >
                      Edit Risk Area
                    </MenuItem>
                  )}
                  {canAddGuideline && (
                    <MenuItem
                      icon={<Icon as={PlusIcon} boxSize={5} />}
                      onClick={onAddGuideline}
                    >
                      Add Guideline
                    </MenuItem>
                  )}
                  {canDeleteRiskArea && (
                    <>
                      <MenuDivider />
                      <DangerMenuItem
                        icon={<Icon as={TrashIcon} boxSize={5} />}
                        onClick={onDeleteRiskArea}
                      >
                        Delete Risk Area
                      </DangerMenuItem>
                    </>
                  )}
                </MenuList>
              </Menu>
            </Box>
          )}
          <Icon as={collapsed ? ChevronDownIcon : ChevronUpIcon} boxSize={5} />
        </HStack>
      </HStack>
      {!collapsed && children}
    </>
  );
};

type GuidelineItemProps = {
  guideline: Guideline;
  onEdit: (guideline: Guideline) => void;
  onDelete: (guideline: Guideline) => void;
};

const GuidelineItem = ({ onEdit, onDelete, guideline }: GuidelineItemProps) => {
  const { userHasPermission } = useContext(UsersContext);
  const canUpdateGuideline = userHasPermission(['update_guideline'], 'all');
  const canDeleteGuideline = userHasPermission(['delete_guideline'], 'all');
  return (
    <VStack
      role="group"
      _hover={{
        bg: useColorModeValue('neutral.25', 'neutral.1000'),
      }}
      p={2}
      gap={0}
      alignItems="flex-start"
      w="full"
    >
      <HStack justifyContent="space-between" w="100%">
        <Heading as={'h5'}>{guideline.title}</Heading>
        {(canUpdateGuideline || canDeleteGuideline) && (
          <Box mx={1} onClick={e => e.stopPropagation()}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                variant="ghost"
                visibility={'hidden'}
                _groupHover={{ visibility: 'visible' }}
                size="sm"
              />
              <MenuList>
                {canUpdateGuideline && (
                  <MenuItem
                    icon={<Icon as={PencilIcon} boxSize={5} />}
                    onClick={e => onEdit(guideline)}
                  >
                    Edit Guideline
                  </MenuItem>
                )}
                {canDeleteGuideline && (
                  <>
                    <MenuDivider />
                    <DangerMenuItem
                      icon={<Icon as={TrashIcon} boxSize={5} />}
                      onClick={e => onDelete(guideline)}
                    >
                      Delete Guideline
                    </DangerMenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Box>
        )}
      </HStack>
      <Text
        className={'ck ck-content'}
        dangerouslySetInnerHTML={{ __html: guideline.description }}
        pr={8}
      ></Text>
    </VStack>
  );
};

const UpdateGuidelineModal = ({
  guideline,
  isOpen,
  onClose,
  onSubmit,
}: {
  guideline: Guideline | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (title: string, description: string) => Promise<void>;
}) => {
  const [title, setTitle] = useState(guideline?.title ?? '');
  const [description, setDescription] = useState(guideline?.description ?? '');

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setTitle('');
      setDescription('');
    } else if (!!guideline) {
      setTitle(guideline.title);
      setDescription(guideline.description);
    }
  }, [isOpen, guideline]);

  const isEdit = !!guideline;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} trapFocus={false}>
      <ModalOverlay />
      <ModalContent bg="neutral.50">
        <ModalHeader>{isEdit ? 'Edit' : 'Add New'} Guideline</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <Label mb={2}>Title</Label>
              <Input value={title} onChange={e => setTitle(e.target.value)} />
            </FormControl>
            <FormControl>
              <Label mb={2}>Description</Label>
              <CKEditorWrapper
                text={description}
                onChange={setDescription}
                readOnly={false}
                enabledFeatures={{
                  images: false,
                  comments: false,
                  revisions: false,
                  deleteBlock: false,
                  generateWithAI: false,
                }}
                hideTooltip={true}
                showOutline={true}
                autoSave={false}
                withHeight={true}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={!title || !description}
            isLoading={isSubmitting}
            colorScheme="brand"
            onClick={async () => {
              try {
                setIsSubmitting(true);
                await onSubmit(title, description);
                onClose();
              } catch (e) {
                console.error(e);
              } finally {
                setIsSubmitting(false);
              }
            }}
          >
            {!isEdit ? 'Add' : 'Save'} Guideline
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const UpdateRiskArea = ({
  riskArea,
  isOpen,
  onClose,
  onSubmit,
}: {
  riskArea: TRiskArea | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string, description: string) => Promise<void>;
}) => {
  const { setInSettings } = useContext(SidebarContext);
  const [name, setName] = useState(riskArea?.name ?? '');
  const [description, setDescription] = useState(riskArea?.description ?? '');

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setName('');
      setDescription('');
    } else if (!!riskArea) {
      setName(riskArea.name);
      setDescription(riskArea.description);
    }
  }, [isOpen, riskArea]);

  const isEdit = !!riskArea;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
      <ModalOverlay />
      <ModalContent bg="neutral.50">
        <ModalHeader>{isEdit ? 'Edit' : 'Add'} Risk Area</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <Label mb={2}>Name</Label>
              <Input value={name} onChange={e => setName(e.target.value)} />
            </FormControl>
            <FormControl>
              <Label mb={2}>Description</Label>
              <Textarea
                rows={10}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isSubmitting}
            colorScheme="brand"
            isDisabled={!name || !description}
            onClick={async () => {
              try {
                setIsSubmitting(true);
                await onSubmit(name, description);
                onClose();
              } catch (e) {
                console.error(e);
              } finally {
                setIsSubmitting(false);
              }
            }}
          >
            {!isEdit ? 'Add' : 'Save'} Risk Area
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeleteRiskAreaModal = ({
  riskArea,
  isOpen,
  onClose,
  onSubmit,
}: {
  riskArea: TRiskArea;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: dependencies = null,
    isFetching,
    refetch,
  } = useQuery(
    ['riskAreas', 'dependencies'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const data = await API.GetRiskAreaDependencies(
        accessToken,
        riskArea.cuid,
      );
      return data;
    },
    {
      enabled: isOpen && !!riskArea,
      staleTime: 0,
    },
  );

  const isLoading = isFetching || !dependencies;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
      <ModalOverlay />
      <ModalContent bg="neutral.50">
        <ModalHeader>Delete Risk Area</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LoadingContainer isLoading={isLoading}>
            <VStack spacing={4} alignItems="flex-start">
              <Text>
                {dependencies?.has_dependencies
                  ? 'This risk area has dependencies and cannot be deleted.'
                  : 'Are you sure you want to delete this risk area?'}
              </Text>
              {!!dependencies?.guidelines.length && (
                <Alert status="error">
                  <AlertIcon />
                  <Box bg="">
                    <Text fontWeight="bold">Risk area has guidelines:</Text>
                    <AlertDescription>
                      <UnorderedList>
                        {dependencies?.guidelines.map(guideline => (
                          <ListItem>{guideline.title}</ListItem>
                        ))}
                      </UnorderedList>
                    </AlertDescription>
                  </Box>
                </Alert>
              )}
              {!!dependencies?.findings.length && (
                <Alert status="error">
                  <AlertIcon />
                  <Box bg="">
                    <Text fontWeight="bold">
                      Risk area belongs to Model Findings:
                    </Text>
                    <AlertDescription>
                      <UnorderedList>
                        {dependencies?.findings.map(finding => (
                          <ListItem>
                            <Link
                              href={`/model-inventory/${finding.inventory_model_cuid}/findings/${finding.cuid}`}
                              target="_blank"
                              textDecoration={'underline'}
                            >
                              {finding.title}
                            </Link>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    </AlertDescription>
                  </Box>
                </Alert>
              )}
            </VStack>
          </LoadingContainer>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isSubmitting || isLoading}
            colorScheme="brand"
            isDisabled={
              dependencies?.has_dependencies || isSubmitting || isLoading
            }
            onClick={async () => {
              try {
                setIsSubmitting(true);
                await onSubmit();
                onClose();
              } catch (e) {
                console.error(e);
              } finally {
                setIsSubmitting(false);
              }
            }}
          >
            Delete Risk Area
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeleteGuidelineModal = ({
  guideline,
  isOpen,
  onClose,
  onSubmit,
}: {
  guideline: Guideline;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [deleteAcknowledged, setDeleteAcknowledged] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setDeleteAcknowledged(false);
    }
  }, [isOpen]);

  const {
    data: dependencies = null,
    isFetching,
    refetch,
  } = useQuery(
    ['guideline', 'dependencies'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const data = await API.GetGuidelineDependencies(
        accessToken,
        guideline.cuid,
      );
      return data;
    },
    {
      enabled: isOpen && !!guideline,
      staleTime: 0,
    },
  );

  let groupedTemplates;

  if (dependencies && dependencies.templates.length > 0) {
    groupedTemplates = _.groupBy(dependencies.templates, 'template_cuid');
  }

  const isLoading = isFetching || !dependencies;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
      <ModalOverlay />
      <ModalContent bg="neutral.50">
        <ModalHeader>Delete Guideline</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LoadingContainer isLoading={isLoading}>
            <VStack spacing={4} alignItems="flex-start">
              <Text>
                {dependencies?.has_dependencies
                  ? 'This guideline has dependencies and cannot be deleted.'
                  : 'Are you sure you want to delete this guideline?'}
              </Text>
              {!!groupedTemplates && (
                <Alert status="warning">
                  <AlertIcon />
                  <Box bg="">
                    <Text fontWeight="bold">
                      Guideline belongs to the following templates:
                    </Text>
                    <AlertDescription>
                      <UnorderedList>
                        {Object.values(groupedTemplates).map(group => (
                          <ListItem>
                            <HStack spacing={0.5}>
                              <Link
                                href={`/settings/templates/${group[0].template_cuid}/validation_report/latest`}
                                target="_blank"
                                textDecoration="underline"
                              >
                                {group[0].name}
                              </Link>
                              <Text>
                                (v{group.map(g => g.version).join(', v')})
                              </Text>
                            </HStack>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    </AlertDescription>
                  </Box>
                </Alert>
              )}
              {!!dependencies?.assessments.length && (
                <Alert status="error">
                  <AlertIcon />
                  <Box bg="">
                    <Text fontWeight="bold">
                      Guideline has {dependencies?.assessments.length}{' '}
                      assessment{dependencies?.assessments.length > 1 && 's'}
                    </Text>
                  </Box>
                </Alert>
              )}
              {!!groupedTemplates && !dependencies?.has_dependencies && (
                <Checkbox
                  isChecked={deleteAcknowledged}
                  onChange={() => setDeleteAcknowledged(!deleteAcknowledged)}
                >
                  I understand that this will remove the guideline from the
                  validation templates above.
                </Checkbox>
              )}
            </VStack>
          </LoadingContainer>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isSubmitting || isLoading}
            colorScheme="brand"
            isDisabled={
              dependencies?.has_dependencies ||
              isSubmitting ||
              isLoading ||
              (!!groupedTemplates && deleteAcknowledged === false)
            }
            onClick={async () => {
              try {
                setIsSubmitting(true);
                await onSubmit();
                onClose();
              } catch (e) {
                console.error(e);
              } finally {
                setIsSubmitting(false);
              }
            }}
          >
            Delete Guideline
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default function RiskAreas({}: Props) {
  const { userHasPermission } = useContext(UsersContext);
  const { setRiskAreas } = useContext(RiskAreaContext);
  const { getAccessTokenSilently } = useAuth0();
  const [expandedRiskAreas, setExpandedRiskAreas] = useState<string[]>([]);

  const [selectedRiskArea, setSelectedRiskArea] = useState<TRiskArea | null>(
    null,
  );
  const [selectedGuideline, setSelectedGuideline] = useState<Guideline | null>(
    null,
  );

  const guidelineUpdateModel = useDisclosure();
  const guidelineDeleteModal = useDisclosure();
  const riskAreaUpdateModel = useDisclosure();
  const riskAreaDeleteModal = useDisclosure();

  const {
    data: riskAreas = [],
    isLoading: isLoadingRiskAreas,
    refetch: refetchRiskAreas,
  } = useQuery(
    ['riskAreas'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const data = await API.GetRiskAreas(accessToken);
      // Context needs to be updated
      setRiskAreas(data.results);
      return data.results;
    },
    {
      enabled: true,
    },
  );

  const {
    data: guidelines = [],
    isLoading: isLoadingGuidelines,
    refetch: refetchGuidelines,
  } = useQuery(
    ['guidelines'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const data = await API.GetGuidelines(accessToken, undefined, {
        limit: 999999,
      });
      return data.results;
    },
    {
      enabled: userHasPermission(['read_guideline'], 'all'),
    },
  );

  const isLoading = isLoadingRiskAreas || isLoadingGuidelines;

  const canAddRiskArea = userHasPermission(['add_risk_area'], 'all');

  return (
    <LoadingContainer isLoading={isLoading}>
      <UpdateGuidelineModal
        guideline={selectedGuideline}
        isOpen={guidelineUpdateModel.isOpen}
        onClose={() => {
          setSelectedGuideline(null);
          guidelineUpdateModel.onClose();
        }}
        onSubmit={async (title, description) => {
          const accessToken = await getAccessTokenSilently();
          if (!selectedGuideline) {
            await API.PostGuideline(accessToken, {
              title,
              description,
              risk_area_cuid: selectedRiskArea!.cuid,
              content_id: `validation.guidelines.${uuidv4()}`,
              require_notes: true,
            });
            await refetchGuidelines();
          } else {
            await API.PatchGuideline(accessToken, selectedGuideline.cuid, {
              title,
              description,
              risk_area_cuid: selectedRiskArea!.cuid,
              require_notes: true,
            });
            await refetchGuidelines();
          }
        }}
      />
      <UpdateRiskArea
        riskArea={selectedRiskArea}
        isOpen={riskAreaUpdateModel.isOpen}
        onClose={() => {
          setSelectedGuideline(null);
          riskAreaUpdateModel.onClose();
        }}
        onSubmit={async (name, description) => {
          const accessToken = await getAccessTokenSilently();
          if (!selectedGuideline) {
            await API.PostRiskArea(accessToken, {
              name,
              description,
            });
            await refetchRiskAreas();
          } else {
            await API.PatchRiskArea(accessToken, selectedRiskArea!.cuid, {
              name,
              description,
            });
            await refetchRiskAreas();
          }
        }}
      />
      <DeleteRiskAreaModal
        riskArea={selectedRiskArea!}
        isOpen={riskAreaDeleteModal.isOpen}
        onClose={() => {
          setSelectedRiskArea(null);
          riskAreaDeleteModal.onClose();
        }}
        onSubmit={async () => {
          const accessToken = await getAccessTokenSilently();
          await API.DeleteRiskArea(accessToken, selectedRiskArea!.cuid);
          await refetchRiskAreas();
        }}
      />
      <DeleteGuidelineModal
        guideline={selectedGuideline!}
        isOpen={guidelineDeleteModal.isOpen}
        onClose={() => {
          setSelectedGuideline(null);
          guidelineDeleteModal.onClose();
        }}
        onSubmit={async () => {
          const accessToken = await getAccessTokenSilently();
          await API.DeleteGuideline(accessToken, selectedGuideline!.cuid);
          await refetchGuidelines();
        }}
      />

      <VStack
        alignItems="start"
        spacing={0}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={4}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        data-testid="groups-settings"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <Breadcrumbs />
        <ContentPageTitle>
          Risk Areas & Validation Guidelines
          <MoreInfoPopOver
            title="Manage Validation Guidelines"
            link="https://docs.validmind.ai/guide/model-validation/manage-validation-guidelines.html"
            placement="right-end"
            iconProps={{
              ml: 2,
              fill: 'brand.base',
            }}
          />
        </ContentPageTitle>

        <Box w="100%" maxWidth="750px">
          <Box mb={2} display="flex" justifyContent="flex-end" w="100%">
            {canAddRiskArea && (
              <Button
                onClick={() => {
                  setSelectedRiskArea(null);
                  setSelectedGuideline(null);
                  riskAreaUpdateModel.onOpen();
                }}
                data-testid="add-risk-area-btn"
                leftIcon={<Icon as={PlusIcon} boxSize={5} />}
                variant={'ghost'}
              >
                Add Risk Area
              </Button>
            )}
          </Box>
          {riskAreas.map(riskArea => {
            const guidelinesForRiskArea = guidelines.filter(
              guideline => guideline.risk_area?.cuid === riskArea.cuid,
            );
            return (
              <RiskAreaAccordionItem
                collapsed={!expandedRiskAreas.includes(riskArea.cuid)}
                onCollapse={() => {
                  setExpandedRiskAreas(prev => {
                    if (prev.includes(riskArea.cuid)) {
                      return prev.filter(cuid => cuid !== riskArea.cuid);
                    } else {
                      return [...prev, riskArea.cuid];
                    }
                  });
                }}
                riskArea={riskArea}
                onAddGuideline={() => {
                  setSelectedGuideline(null);
                  setSelectedRiskArea(riskArea);
                  guidelineUpdateModel.onOpen();
                }}
                onUpdateRiskArea={() => {
                  setSelectedGuideline(null);
                  setSelectedRiskArea(riskArea);
                  riskAreaUpdateModel.onOpen();
                }}
                onDeleteRiskArea={() => {
                  setSelectedRiskArea(riskArea);
                  riskAreaDeleteModal.onOpen();
                }}
              >
                <Box mx={4} mt={4} mb={2}>
                  <Box
                    className={'ck ck-content'}
                    dangerouslySetInnerHTML={{ __html: riskArea.description }}
                  />
                  <Divider my={4} />
                  <Heading as={'h4'}>Guidelines</Heading>
                </Box>
                <VStack gap={0} mx={2} mb={2}>
                  {guidelinesForRiskArea.map(guideline => {
                    return (
                      <GuidelineItem
                        guideline={guideline}
                        onEdit={g => {
                          setSelectedRiskArea(riskArea);
                          setSelectedGuideline(g);
                          guidelineUpdateModel.onOpen();
                        }}
                        onDelete={g => {
                          setSelectedGuideline(guideline);
                          guidelineDeleteModal.onOpen();
                        }}
                      />
                    );
                  })}
                </VStack>
              </RiskAreaAccordionItem>
            );
          })}
        </Box>
      </VStack>
    </LoadingContainer>
  );
}
