import { useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Handle, Position, HandleProps } from 'reactflow';

interface CustomHandleProps extends HandleProps {
  position: Position;
  type: 'source' | 'target';
  isConnectable?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const CustomHandle: React.FC<CustomHandleProps> = ({
  position,
  type,
  isConnectable = true,
  style = {},
  ...props
}) => {
  const handleBackground = useColorModeValue(
    'var(--chakra-colors-white)',
    'var(--chakra-colors-black)',
  );
  const handleBorderColor = useColorModeValue(
    'var(--chakra-colors-neutral-500)',
    'var(--chakra-colors-neutral-500)',
  );

  const handleStyles = {
    background: handleBackground,
    borderColor: handleBorderColor,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 12,
    width: 12,
    ...style,
  };

  return (
    <Handle
      type={type}
      position={position}
      isConnectable={isConnectable}
      style={handleStyles}
      {...props}
    />
  );
};

export default CustomHandle;
