import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorModeValue,
  Wrap,
} from '@chakra-ui/react';
import { ContentPageTitle } from '../../components/Layout';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { allFiltersEmpty } from '../../utils';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APIRequestSortBy } from '../../models/utils';
import { BarsArrowDownIcon } from '@heroicons/react/20/solid';
import { Copyright } from '../../components/Copyright';
import {
  ArrowDownTrayIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import GetStartedChecklist from '../../components/GetStartedChecklist';

import { FindingFilters } from '../../models/finding';
import ModelFindingsFilters from '../../components/ModelFindingsFilters';
import TableColumnPicker from '../../components/TableColumnPicker';
import { useUserUISettings } from '../../hooks/useUserUISettings';
import AvatarProxy from '../../components/AvatarProxy';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import ManageViewsButton from '../../components/ManageViewsButton';
import { TSavedView } from '../../models/saved_view';
import { useFlags } from '../../hooks/useFlags';
import FindingsTable, {
  ALL_FINDINGS_COLUMNS,
  getLabelFromSortType,
  sortFindingsOptions,
} from '../../components/FindingsTable';
import dayjs from 'dayjs';

type FilterDefinition = {
  accessors?: {
    id?: string;
    label?: string;
    picture?: string;
  };
  tag: string;
};

const filterDefs: { [key: string]: FilterDefinition } = {
  business_units: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Business Unit',
  },
  status: {
    tag: 'Status',
  },
  assignees: {
    accessors: {
      id: 'cuid',
      label: 'name',
      picture: 'picture',
    },
    tag: 'Assigned To',
  },
  risk_areas: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Risk Area',
  },
  severities: {
    accessors: {
      id: 'id',
      label: 'name',
    },
    tag: 'Severity',
  },
  inventory_models: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Inventory Model',
  },
};

const defaultColumns = [
  'title',
  'severity',
  'status',
  'inventory_model.name',
  'due_at',
  'created_at',
];

const initialSortBy = sortFindingsOptions[0];

export default function ModelFindings() {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const filtersState = searchParams.get('filters');
  const [selectedView, setSelectedView] = useState<TSavedView>();

  const { getModelFindingsColumns, updateModelFindingsColumns } =
    useUserUISettings();

  const storedColumnConfig = getModelFindingsColumns();

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    storedColumnConfig ? storedColumnConfig : defaultColumns,
  );

  const [selectedFilters, setSelectedFilters] = useState<
    FindingFilters | undefined
  >(filtersState ? JSON.parse(decodeURIComponent(filtersState)) : undefined);

  const [sortBy, setSortBy] = useState<APIRequestSortBy | undefined>(
    initialSortBy,
  );

  const {
    modelInventorySavedViews: modelInventorySavedViewsFlag,
    inventoryAndFindingsExport: inventoryAndFindingsExportFlag,
  } = useFlags();

  const { data: allFilterOptions, isLoading: loadingFilterOptions } = useQuery(
    ['model-findings', 'filters'],
    async () => {
      const accessToken = await getAccessTokenSilently();

      return API.GetFindingFilters(accessToken);
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  useEffect(() => {
    if (selectedFilters) {
      setSearchParams(
        `?filters=${encodeURIComponent(JSON.stringify(selectedFilters))}`,
      );
    }
  }, [selectedFilters]);

  const onTableSort = (column: any[]) => {
    if (column.length > 0) {
      const c = ALL_FINDINGS_COLUMNS.find(s => s.accessor === column[0].id)!;
      const order = column[0].desc ? 'desc' : 'asc';

      setSortBy({
        label: c.Header,
        field: c.sortField,
        order,
        orderLabel: getLabelFromSortType(c.sortType, order),
      });
    } else {
      setSortBy(initialSortBy);
    }
  };

  const filterDisplays: any[] = [];

  // The purpose of this code is to map selectFilters, which contains list of ids
  // for each filter, into allFilterOptions, which contains the data record for
  // the filter
  if (selectedFilters && allFilterOptions) {
    Object.keys(selectedFilters).forEach((selectedFilterKey: string) => {
      const filterDef = filterDefs[selectedFilterKey];

      if (!filterDef) {
        return;
      }

      // @ts-ignore:
      const allFiltersForKey = allFilterOptions[selectedFilterKey];

      // @ts-ignore:
      selectedFilters[selectedFilterKey].forEach(selectedId => {
        const idKey = filterDef.accessors?.id;
        let label;
        let picture;

        if (idKey) {
          const filterRecord = allFiltersForKey.find(
            (a: any) => a[idKey] === selectedId,
          );
          label = filterRecord[filterDef.accessors?.label];
          picture = filterRecord[filterDef.accessors?.picture];
        } else {
          label = selectedId;
        }

        filterDisplays.push(
          <Tag
            variant={'outline'}
            colorScheme={'neutral'}
            key={selectedId}
            size={'md'}
          >
            {picture && (
              <AvatarProxy
                src={picture}
                size="xs"
                name={label}
                ml={-1}
                mr={2}
              />
            )}
            <TagLabel>
              <strong>{filterDef.tag}:</strong> {label}
            </TagLabel>
            <TagCloseButton
              onClick={() =>
                setSelectedFilters({
                  ...selectedFilters,
                  // @ts-ignore:
                  [selectedFilterKey]: selectedFilters[
                    selectedFilterKey
                  ].filter((o: any) => {
                    return o !== selectedId;
                  }),
                })
              }
            />
          </Tag>,
        );
      });
    });
  }

  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const accessToken = await getAccessTokenSilently();
      const csvData = await API.ExportFindings(
        accessToken,
        selectedFilters,
        sortBy,
      );

      const timestamp = dayjs().format('YYYY-MM-DD-HH-mm-ss');
      const url = window.URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `findings-${timestamp}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export findings:', error);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <Box
      py={10}
      px={8}
      flex={1}
      w={'full'}
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'black')}
    >
      <Stack>
        <HStack>
          <Flex width={'full'} justify={'space-between'}>
            <HStack gap={5} pl={2} color={'inherit'}>
              <Icon as={ExclamationTriangleIcon} boxSize={10} />
              <ContentPageTitle>
                Model Findings
                <MoreInfoPopOver
                  title="Model Findings"
                  description="View your findings across all models."
                  link="https://docs.validmind.ai/guide/model-validation/view-filter-model-findings.html"
                  placement="right-end"
                  iconProps={{
                    ml: 2,
                  }}
                />
              </ContentPageTitle>
            </HStack>
          </Flex>
        </HStack>
        <HStack gap={8} w={'full'} pl={0.5} justifyContent={'space-between'}>
          {modelInventorySavedViewsFlag === true && (
            <ManageViewsButton
              viewType="finding"
              selectedView={selectedView}
              onViewSelected={(view: TSavedView) => {
                setSelectedColumns(view.content.columns);
                setSortBy(view.content.sortBy);
                setSelectedFilters(view.content.filters);
                setSelectedView(view);
              }}
              getCurrentContent={() => ({
                columns: selectedColumns,
                sortBy,
                filters: selectedFilters,
              })}
            />
          )}

          <HStack>
            {allFilterOptions && (
              <ModelFindingsFilters
                selectedFilters={selectedFilters}
                allFilterOptions={allFilterOptions}
                setFilters={setSelectedFilters}
              />
            )}
            <TableColumnPicker
              selectedColumnIds={selectedColumns}
              allColumns={ALL_FINDINGS_COLUMNS.map(c => ({
                id: c.accessor,
                label: c.Header,
              }))}
              setColumns={newColumns => {
                updateModelFindingsColumns(newColumns);
                setSelectedColumns(newColumns as any);
              }}
            />
            {inventoryAndFindingsExportFlag === true && (
              <Button
                leftIcon={<Icon as={ArrowDownTrayIcon} />}
                onClick={handleExport}
                variant="ghost"
                isLoading={isExporting}
                loadingText="Exporting..."
                disabled={isExporting}
              >
                Export
              </Button>
            )}
          </HStack>
        </HStack>

        <HStack
          px={5}
          alignItems={'flex-start'}
          gap={selectedFilters && !allFiltersEmpty(selectedFilters) ? 4 : 0}
        >
          {selectedFilters && !allFiltersEmpty(selectedFilters) && (
            <HStack alignItems={'flex-start'}>
              <Flex
                alignItems={'center'}
                gap={2}
                color={'neutral.500'}
                whiteSpace={'nowrap'}
              >
                <Icon as={FunnelIcon} width={5} height={5} />
                <Text fontSize={'sm'} fontWeight={'semibold'}>
                  Filtered by:
                </Text>
              </Flex>

              <Flex>
                {selectedFilters && (
                  <Wrap>
                    {selectedFilters.cuids && selectedFilters.cuids.length > 0 && (
                      <Tag
                        key={'cuid-filter-tag'}
                        size={'md'}
                        borderRadius="full"
                      >
                        <TagLabel>
                          <strong>ID Filter: </strong>{' '}
                          {selectedFilters.cuids.length} Findings
                        </TagLabel>
                        <TagCloseButton
                          onClick={() =>
                            setSelectedFilters({
                              ...selectedFilters,
                              cuids: undefined,
                            })
                          }
                        />
                      </Tag>
                    )}
                    {filterDisplays}
                  </Wrap>
                )}
              </Flex>
            </HStack>
          )}

          {sortBy && (
            <HStack margin={'0 !important'}>
              <Flex
                alignItems={'center'}
                gap={2}
                whiteSpace={'nowrap'}
                color={'neutral.500'}
              >
                <Icon as={BarsArrowDownIcon} width={5} height={5} />
                <Text fontSize={'sm'} fontWeight={'semibold'}>
                  Sorted by:
                </Text>

                <Wrap>
                  {sortBy && (
                    <Tag key={sortBy.field} size={'md'}>
                      <TagLabel>
                        <strong>{sortBy.label}: </strong>
                        {sortBy.orderLabel}
                      </TagLabel>
                    </Tag>
                  )}
                </Wrap>
              </Flex>
            </HStack>
          )}
          {selectedView && (
            <Button
              variant={'ghost'}
              size={'xs'}
              fontSize={'sm'}
              rounded={'full'}
              leftIcon={<Icon as={XMarkIcon} boxSize={4} />}
              onClick={() => {
                setSelectedView(undefined);
                setSelectedColumns(defaultColumns);
                setSortBy(initialSortBy);
                setSelectedFilters(undefined);
              }}
            >
              Clear All
            </Button>
          )}
        </HStack>
        <FindingsTable
          columns={selectedColumns}
          sortBy={sortBy}
          filters={selectedFilters}
          onClickRow={(row: any) => {
            navigate(
              `/model-inventory/${row.original.inventory_model.cuid}/findings/${row.original.cuid}`,
            );
          }}
          onTableSort={onTableSort}
        />
      </Stack>
      <Copyright />
      <GetStartedChecklist />
    </Box>
  );
}
