import { NodeProps, Position, useStore } from 'reactflow';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { generateRandomCodeForNodes } from '../../../../../../../utils';
import NodeBox from '../../../layouts/NodeBox';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';
import useWorkflow from '../../../../../../../hooks/useWorkflow';
import { BranchRouterNodeType } from '../../../types';
import { useMemo } from 'react';
import { Branch } from '../../../Panels/BranchPanel';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import '../styles.css';
import CustomHandle from '../../../handles/CustomHandle';

const zoomSelector = (s: any) => s.transform[2] >= 0.7;

function BranchRouterNode(props: NodeProps) {
  const { setSelectedNodeId } = useWorkflow();

  const showContent = useStore(zoomSelector);

  if (props.data.state_callbacks.on_enter[0].args.branches.length === 0) {
    return (
      <NodeBox
        title={'Condition Branch'}
        icon={ArrowsPointingOutIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <HStack px={2} pb={2} w={'full'}>
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            size={'sm'}
            variant={'primary'}
          >
            Configure
          </Button>
        </HStack>
      </NodeBox>
    );
  }

  const hasAtLeastTwoEqual = useMemo(() => {
    const items = props.data.state_callbacks.on_enter[0].args.branches.map(
      (b: Branch) => b.query,
    );
    const itemSet = new Set<string>();
    for (const item of items) {
      if (itemSet.has(item)) {
        return true;
      }
      itemSet.add(item);
    }
    return false;
  }, [props.data.state_callbacks.on_enter[0].args.branches]);

  return (
    <NodeBox
      title={'Condition Branch'}
      icon={ArrowsPointingOutIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
    >
      <VStack px={showContent ? 4 : 2} py={2}>
        <Box className="hex-container">
          <VStack gap={0} p={4}>
            <Text fontSize={showContent ? 'sm' : '2xl'}>
              {props.data.state_callbacks.on_enter[0].args.branches.length} path
              {props.data.state_callbacks.on_enter[0].args.branches.length >
                1 && `s`}
            </Text>
          </VStack>
        </Box>

        {hasAtLeastTwoEqual && (
          <Stack alignItems={'flex-start'} px={2} pb={2}>
            <Alert status="warning" size={'xs'}>
              <AlertIcon />
              <Text fontSize={'xs'}>Conditions must be mutually exclusive</Text>
            </Alert>
          </Stack>
        )}
      </VStack>
      <CustomHandle
        type="target"
        position={Position.Top}
        style={{ top: -6 }}
        id="top"
      />
      <CustomHandle
        type="target"
        position={Position.Right}
        style={{ right: -6 }}
        id="right"
      />
      <CustomHandle
        type="source"
        position={Position.Bottom}
        style={{ bottom: -6 }}
        id="bottom"
      />
      <CustomHandle
        type="source"
        position={Position.Left}
        style={{ left: -6 }}
        id="left"
      />
    </NodeBox>
  );
}

BranchRouterNode.type = 'branch_router';
BranchRouterNode.autoRuns = true;

BranchRouterNode.getDefaultNode = (): BranchRouterNodeType => {
  return {
    id: `${BranchRouterNode.type}_${generateRandomCodeForNodes()}`,
    type: BranchRouterNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_branch_resolver',
            args: {
              branches: [
                {
                  trigger_id: null,
                  name: 'default',
                  query: '{"and": [true]}',
                  is_default: true,
                },
              ],
            },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default BranchRouterNode;
