import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  BoxProps,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Text,
  ToastId,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import API, { TFindingRequest } from '../../api/API';
import { TFinding, TSeverity } from '../../models/finding';
import { Label } from '../Layout';
import { toInteger } from 'lodash';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import UsersContext from '../../contexts/UsersContext';
import InventoryModelContext from '../../contexts/InventoryModel';
import RichTextContentEditor from '../RichTextContentEditor';
import RiskAreaContext from '../../contexts/RiskAreaContext';
import { PlusIcon } from '@heroicons/react/20/solid';
import DocumentSectionSelect from '../DocumentSectionSelect';
import { InventoryModelStages } from '../../models/inventory_model';
import MoreInfoPopOver from '../MoreInfoPopOver';

interface IAddFindingModalProps {
  onFindingAdded?: (finding: TFinding) => void;
  buttonBoxProps?: BoxProps;
  buttonVariant?: 'primary' | 'secondary' | 'ghost';
  triggerLabel?: string;
  sectionId?: string;
}

export default function AddFindingModal({
  onFindingAdded,
  buttonBoxProps = {},
  buttonVariant = 'primary',
  triggerLabel = 'Add Finding',
  sectionId,
}: IAddFindingModalProps) {
  const { getAccessTokenSilently, user } = useAuth0();
  const { organizationUsers, currentUser } = useContext(UsersContext);
  const { inventoryModel, userHasInventoryModelPermission } = useContext(
    InventoryModelContext,
  );
  const { riskAreas } = useContext(RiskAreaContext);

  const toast = useToast();

  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [ownerCuid, setOwnerCuid] = React.useState('');
  const [riskAreaCuid, setRiskAreaCuid] = React.useState('');
  const [severity, setSeverity] = React.useState<TSeverity | undefined>();
  const [dueDate, setDueDate] = React.useState('');
  const [documentationSectionId, setDocumentationSection] =
    React.useState(sectionId);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toastIdRef = React.useRef<ToastId>();

  const { data: severities = [] } = useQuery(
    ['findings', 'severities'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetFindingSeverities(accessToken);
    },
    {
      staleTime: 1000 * 60 * 60,
    },
  );

  const postFindingMutation = useMutation(
    ['inventory-model', inventoryModel?.cuid, 'create-finding'],
    async () => {
      const accessToken = await getAccessTokenSilently();

      let findingDueDate;
      if (dueDate) {
        findingDueDate = new Date(dueDate).getTime();
      }

      const findingRequestBody: TFindingRequest = {
        title,
        description,
        metadata: {},
        risk_area_cuid: riskAreaCuid,
        due_at: findingDueDate,
        owner_cuid: ownerCuid,
        severity: Number(severity?.level),
        documentation_section_id: documentationSectionId || null,
      };

      return API.PostFinding(inventoryModel!, accessToken, findingRequestBody);
    },
    {
      onSuccess: newFinding => {
        closeModal();
        addToast(newFinding);
        onFindingAdded?.(newFinding);
      },
    },
  );

  useEffect(() => {
    const sortedSeverities = severities?.sort(
      (a: TSeverity, b: TSeverity) => Number(a.level) - Number(b.level),
    );
    setSeverity(sortedSeverities?.[0]);
  }, [severities]);

  const canAddOrUpdateFinding = userHasInventoryModelPermission(
    ['add_finding', 'update_finding'],
    'any',
  );

  if (currentUser && !canAddOrUpdateFinding) {
    return null;
  }

  const FindingCreatedToast = ({ finding }: { finding: TFinding }) => (
    <Box bg="green.100" color={'green.900'} p={4} rounded="lg" shadow="xl">
      <Stack>
        <Heading as={'h4'}>Finding Created</Heading>
        <Text fontSize="md">Add remediation plans, attachments, or comments on the finding details page.</Text>
        <HStack>
          <Button variant={'ghost'} onClick={closeToast}>
            Ok
          </Button>
          <Spacer />
          <Button
            bg={'green.200'}
            color={'green.900'}
            _hover={{ bg: 'green.300' }}
          >
            <Link
              to={`/model-inventory/${inventoryModel?.cuid}/findings/${finding.cuid}`}
              onClick={closeToast}
            >
              View Finding Details
            </Link>
          </Button>
        </HStack>
      </Stack>
    </Box>
  );

  const addToast = (newFinding: TFinding) => {
    toastIdRef.current = toast({
      position: 'bottom-right',
      duration: 10000,
      isClosable: true,
      status: 'success',
      variant: 'subtle',

      render: () => <FindingCreatedToast finding={newFinding} />,
    });
  };

  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };

  const closeModal = () => {
    postFindingMutation.reset();
    setTitle('');
    setDescription('');
    setOwnerCuid('');
    onClose();
  };

  const saveDisabled =
    title === '' ||
    description === '' ||
    !riskAreaCuid ||
    postFindingMutation.isLoading;

  const buttonPrimaryProps = {};
  const buttonSecondaryProps = {
    leftIcon: <Icon as={ExclamationTriangleIcon} boxSize={5} />,
    backgroundColor: 'neutral.800',
    color: 'neutral.100',
    _hover: { bg: 'neutral.900' },
  };
  const buttonGhostProps = {
    variant: 'ghost',
  };

  const getButtonProps = (variant: string) => {
    if (variant === 'primary') {
      return buttonPrimaryProps;
    } else if (variant === 'ghost') {
      return buttonGhostProps;
    } else {
      return buttonSecondaryProps;
    }
  };

  const editorBgColor = useColorModeValue('white', 'neutral.800');

  return (
    <>
      <Box
        hidden={inventoryModel?.stage !== InventoryModelStages.ACTIVE}
        {...buttonBoxProps}
      >
        {canAddOrUpdateFinding && (
          <Button
            onClick={onOpen}
            data-testid="add-finding-btn"
            leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            {...getButtonProps(buttonVariant)}
            variant={'outline'}
          >
            {triggerLabel}
          </Button>
        )}
      </Box>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={closeModal}
        size="3xl"
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent bg="neutral.50" data-testid="add-finding-modal">
          <ModalHeader>
            Add Model Finding
            <MoreInfoPopOver
              title="Add Model Findings"
              link="https://docs.validmind.ai/guide/model-validation/add-manage-model-findings.html#add-model-findings"
              placement="bottom"
              iconProps={{
                ml: 2,
              }}
            />
          </ModalHeader>
          <ModalCloseButton data-testid="close-add-finding-modal-btn" />
          <ModalBody>
            <Stack gap={4}>
              {postFindingMutation.isError && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle fontSize="sm">
                    Could not create finding:
                  </AlertTitle>
                  <AlertDescription fontSize="sm">
                    {API.getAPIErrorMessage(postFindingMutation.error)}
                  </AlertDescription>
                </Alert>
              )}

              <FormControl isRequired>
                <Label mb={2}>TITLE:</Label>
                <Input
                  type="text"
                  value={title}
                  onChange={event => setTitle(event.target.value)}
                />
              </FormControl>
              <HStack>
                <FormControl isRequired>
                  <Label mb={2}>RISK AREA:</Label>
                  <Select
                    onChange={e => {
                      setRiskAreaCuid(e.target.value);
                    }}
                    placeholder="Select option"
                  >
                    {riskAreas.sort().map(area => (
                      <option key={area.cuid} value={area.cuid}>
                        {area.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <Label mb={2}>OWNER:</Label>
                  <Select
                    onChange={e => setOwnerCuid(e.target.value)}
                    placeholder="Select assignee"
                  >
                    {organizationUsers &&
                      organizationUsers.map(user => (
                        <option key={user.cuid} value={user.cuid}>
                          {user.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </HStack>
              <HStack>
                <FormControl>
                  <Label mb={2}>SEVERITY:</Label>
                  <Select
                    onChange={e =>
                      setSeverity(
                        severities?.find(
                          s => s.id === toInteger(e.target.value),
                        ),
                      )
                    }
                    value={severity?.id}
                  >
                    {severities?.map(s => (
                      <option key={s.id} value={s.id}>
                        {s.level} Level ({s.name})
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <Label
                    mb={2}
                    helpText="Target date for completing remediation actions"
                  >
                    DUE DATE
                  </Label>
                  <Input
                    type="date"
                    placeholder="Target date for remediation"
                    onChange={event => setDueDate(event.target.value)}
                    w="100%"
                    size="sm"
                    rounded={'md'}
                    py={'1.2rem'}
                  />
                </FormControl>
              </HStack>
              <HStack>
                <FormControl>
                  <Label mb={2}>DOCUMENTATION SECTION:</Label>
                  <DocumentSectionSelect
                    sectionId={documentationSectionId}
                    setSectionId={setDocumentationSection}
                    documentType="documentation"
                  />
                </FormControl>
              </HStack>
              <FormControl isRequired>
                <Label mb={2}>DESCRIPTION:</Label>
                <Box bg={editorBgColor}>
                  <RichTextContentEditor
                    variant={'form-field'}
                    text={description || ''}
                    onSave={(editorText: string) => setDescription(editorText)}
                    allowEdit={true}
                    placeholder={'Type the finding description here'}
                  />
                </Box>
              </FormControl>
              <FormControl>
                <Label mb={2}>ATTACHMENTS:</Label>
                <FormHelperText>
                  Please save your entry before adding attachments.
                </FormHelperText>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width="100%">
              <Button onClick={closeModal} variant={'ghost'}>
                Cancel
              </Button>
              <Spacer />
              <Button
                disabled={saveDisabled}
                onClick={() => postFindingMutation.mutate()}
                variant={'primary'}
              >
                {postFindingMutation.isLoading ? 'Saving...' : 'Save'}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
