import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import API, { TTestResultKey } from '../../../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import {
  FailedBadge,
  PassedBadge,
  TestResultContent,
} from '../TestResultContent';
import useTestResult from '../../../../hooks/useTestResult';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
import { AssessmentEvidence } from '../../../../models/guideline';
import { TemplateSectionContents } from '../../../../models/template';
import InventoryModelContext from '../../../../contexts/InventoryModel';

interface RiskAssessmentAddEvidenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  evidences: AssessmentEvidence[];
  onSelectedEvidence: (
    documentType: ModelDocumentTypeEnum,
    contents: TemplateSectionContents[],
  ) => void;
  documentType: ModelDocumentTypeEnum;
}

const TestResultAccordion = ({
  testResultKey,
  documentType,
  selected,
  toggleCheckTestResult,
}: {
  testResultKey: TTestResultKey;
  documentType: ModelDocumentTypeEnum;
  selected: TemplateSectionContents[];
  toggleCheckTestResult: (content: TemplateSectionContents) => void;
}) => {
  return (
    <AccordionItem
      key={testResultKey.cuid}
      border={'1px solid'}
      borderColor={useColorModeValue(
        'neutral.200 !important',
        'neutral.800 !important',
      )}
      mb={1}
      overflow={'hidden'}
    >
      {({ isExpanded }) => {
        const { testResult, isLoading } = useTestResult(
          testResultKey.test_name,
          documentType,
          isExpanded,
        );
        return (
          <>
            <AccordionButton
              _hover={{
                bg: useColorModeValue('neutral.50', 'neutral.850'),
              }}
            >
              <HStack w={'full'} justifyContent={'space-between'}>
                <Checkbox
                  size={'lg'}
                  isChecked={selected.some(
                    c => c.content_id === testResultKey.test_name,
                  )}
                  onChange={e => {
                    toggleCheckTestResult({
                      content_id: testResultKey.test_name,
                      content_type: 'test', // TODO: fix this
                    });
                  }}
                >
                  <HStack w={'full'} justifyContent={'space-between'}>
                    <Text fontSize={'md'} pl={2}>
                      {_.startCase(`${testResultKey.test_name}`)}
                    </Text>
                  </HStack>
                </Checkbox>
                <HStack>
                  {testResultKey?.passed === null ? (
                    ''
                  ) : testResultKey?.passed ? (
                    <PassedBadge />
                  ) : (
                    <FailedBadge />
                  )}
                  <AccordionIcon />
                </HStack>
              </HStack>
            </AccordionButton>
            <AccordionPanel pb={4}>
              {isLoading && <Text>Loading...</Text>}
              {testResult && (
                <TestResultContent
                  contents={{
                    content_id: testResultKey.test_name,
                    content_type: documentType,
                  }}
                  readOnly
                  hideHeader={true}
                  documentType={documentType}
                  overrideDocumentType={documentType}
                />
              )}
            </AccordionPanel>
          </>
        );
      }}
    </AccordionItem>
  );
};

export default function RiskAssessmentAddEvidenceModal({
  isOpen,
  onClose,
  onSelectedEvidence,
  evidences,
  documentType,
}: RiskAssessmentAddEvidenceModalProps) {
  const { inventoryModel } = useContext(InventoryModelContext);
  const { getAccessTokenSilently } = useAuth0();
  const [selected, setSelected] = useState<TemplateSectionContents[]>([]);

  useEffect(() => {
    setSelected(
      evidences.map(evidence => {
        return {
          content_id: evidence.evidence_content_id,
          content_type: evidence.evidence_content_type,
        };
      }),
    );
  }, [evidences]);

  const evidenceAuthorType =
    documentType === ModelDocumentTypeEnum.model_documentation
      ? 'Developer'
      : 'Validator';

  const { data: testResultsKeys } = useQuery(
    [inventoryModel?.cuid, 'test-results-keys', documentType],
    async () => {
      const token = await getAccessTokenSilently();
      // published_only is only true when we're adding evidence from the developer, i.e.
      // evidence from the existing model documentaion.
      // for a validator, we want to see all the validator test results, including the unpublished ones.
      const publishedOnly =
        documentType === ModelDocumentTypeEnum.model_documentation;
      return API.GetModelInventoryTestResultsKeys(
        token,
        inventoryModel!,
        publishedOnly,
        documentType,
      );
    },
  );

  const mergedTests = useMemo(() => {
    if (!testResultsKeys) return [];

    return [...testResultsKeys].sort((a, b) =>
      a.test_name.localeCompare(b.test_name),
    );
  }, [testResultsKeys]);

  const toggleCheckTestResult = (content: TemplateSectionContents) => {
    setSelected(prevSelected => {
      const exists = prevSelected.some(
        item =>
          item.content_id === content.content_id &&
          item.content_type === content.content_type,
      );

      if (exists) {
        // Remove content if it exists
        return prevSelected.filter(
          item =>
            !(
              item.content_id === content.content_id &&
              item.content_type === content.content_type
            ),
        );
      } else {
        // Add content if it doesn't exist
        return [...prevSelected, content];
      }
    });
  };

  const onInsert = () => {
    onSelectedEvidence(documentType, selected);
    onClose();
  };

  return (
    <Modal isCentered size={'6xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Link {evidenceAuthorType} Evidence to Validation Report
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Accordion allowToggle>
            {mergedTests.map(mergedTest => {
              const testResultKey = mergedTest as TTestResultKey;
              return (
                <TestResultAccordion
                  testResultKey={testResultKey}
                  documentType={documentType}
                  selected={selected}
                  toggleCheckTestResult={toggleCheckTestResult}
                />
              );
            })}
          </Accordion>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Spacer />
          <Button onClick={onInsert}>Update Linked Evidence</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
