import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import AdminAPI, {
  Organization,
  OrganizationDefaults,
  User,
} from '../../api/AdminAPI';

export default function useOrganizations() {
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(['admin', 'organizations'], async () => {
    const accessToken = await getAccessTokenSilently();
    return await AdminAPI.GetOrganizations(accessToken);
  });

  return {
    organizations: queryResponse.data as Organization[],
    ...queryResponse,
  };
}

export function useOrganizationDefaults() {
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(
    ['admin', 'organization-defaults'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await AdminAPI.GetOrganizationDefaults(accessToken);
    },
  );

  return {
    defaults: queryResponse.data as OrganizationDefaults,
    ...queryResponse,
  };
}

export function useUsers() {
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(['admin', 'users'], async () => {
    const accessToken = await getAccessTokenSilently();
    return await AdminAPI.GetUsers(accessToken);
  });

  return {
    users: queryResponse.data as User[],
    ...queryResponse,
  };
}

export { useOrganizations };
