import { Link } from 'react-router-dom';
import {
  Box,
  Divider,
  Flex,
  IconButton,
  Tooltip,
  useColorModeValue,
  VStack,
  Button,
  Stack,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import SidebarContext from '../../contexts/SidebarContext';
import {
  ArrowTopRightOnSquareIcon,
  BookOpenIcon,
  ChartBarSquareIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  Cog6ToothIcon,
  CubeIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  RocketLaunchIcon,
  ShieldCheckIcon,
  SignalIcon,
  TvIcon,
} from '@heroicons/react/24/outline';
import ValidMindLogo from '../ValidMindLogo';
import ValidMindVLogo from '../ValidMindVLogo';
import { UserDisplay } from '../UserDisplay';
import CubesIcon from '../icons/CubesIcon';
import UsersContext from '../../contexts/UsersContext';
import MenuItem, { IMenuItem } from './MenuItem';
import { Icon, InfoIcon } from '@chakra-ui/icons';

export const Sidebar = () => {
  const {
    SIDEBAR_SIZES,
    inventoryModel,
    inSettings,
    setIsExpanded,
    isExpanded,
  } = useContext(SidebarContext);
  const { currentUser, currentOrganization } = useContext(UsersContext);
  const [menuItems, setMenuItems] = useState<{
    top: IMenuItem[];
    bottom: IMenuItem[];
  }>({
    top: [],
    bottom: [],
  });

  const userHasMoreThanOneOrg = () =>
    currentUser && currentUser.organizations?.length > 1;

  useEffect(() => {
    // Creates navigation structure for menu items, top and bottom navigation zones, filter by user permissions.

    let inventoryModelItems: IMenuItem[] = [];
    if (inventoryModel) {
      inventoryModelItems = [
        {
          key: 'model-overview',
          label: inventoryModel?.name,
          path: `/model-inventory/${inventoryModel?.cuid}/overview`,
          icon: CubeIcon,
        },
        {
          key: 'model-documentation2',
          label: 'Documentation',
          path: `/model-inventory/${inventoryModel?.cuid}/documentation-overview`,
          icon: BookOpenIcon,
        },
        {
          key: 'model-project-vr2',
          label: 'Validation Report',
          path: `/model-inventory/${inventoryModel?.cuid}/validation-report`,
          icon: ShieldCheckIcon,
        },
        {
          key: 'model-ongoing-monitoring2',
          label: 'Ongoing Monitoring',
          path: `/model-inventory/${inventoryModel?.cuid}/monitoring`,
          icon: TvIcon,
        },
        {
          key: 'model-findings',
          label: 'Model Findings',
          path: `/model-inventory/${inventoryModel?.cuid}/findings`,
          icon: ExclamationTriangleIcon,
        },
        {
          key: 'model-activity',
          label: 'Model Activity',
          path: `/model-inventory/${inventoryModel?.cuid}/activity`,
          icon: SignalIcon,
        },
        {
          key: 'project-client2',
          label: 'Getting Started',
          path: `/model-inventory/${inventoryModel?.cuid}/getting-started`,
          icon: RocketLaunchIcon,
        },
      ];
    }

    const top: IMenuItem[] = [
      {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/dashboard',
        icon: HomeIcon,
      },
      {
        key: 'model-inventory',
        label: 'Inventory',
        path: '/model-inventory',
        icon: CubesIcon,
        submenuItems: inventoryModelItems,
      },
      {
        key: 'findings',
        label: 'Findings',
        path: '/model-findings',
        icon: ExclamationTriangleIcon,
      },
      {
        key: 'analytics',
        label: 'Analytics',
        path: '/analytics',
        icon: ChartBarSquareIcon,
      },
      // {
      //   key: 'approvals',
      //   label: 'Approvals',
      //   path: '/approvals',
      //   icon: ShieldCheckIcon,
      // },
    ];

    const bottom: IMenuItem[] = [
      {
        key: 'settings',
        label: 'Settings',
        path: '/settings',
        icon: Cog6ToothIcon,
      },
      {
        key: 'docs',
        label: 'Documentation',
        path: 'https://docs.validmind.ai',
        icon: InfoIcon,
        secondaryIcon: ArrowTopRightOnSquareIcon,
      },
    ];

    setMenuItems({ top, bottom });
  }, [inventoryModel, inSettings, currentUser, currentOrganization?.name]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      data-testid="sidebar"
      position="fixed"
      borderRightWidth={1}
      width={isExpanded ? SIDEBAR_SIZES[0] : SIDEBAR_SIZES[1]}
      transition="width .2s, padding .2s"
      // px={isExpanded ? 4 : 2}
      height="100vh"
      top={0}
      left={0}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={4}
      // pb={4}
      overflow={'auto'}
      bg={useColorModeValue('neutral.25', 'brandSecondary.1000')}
      borderColor={useColorModeValue('neutral.200', 'neutral.900')}
      className="no-scrollbar"
    >
      <VStack gap={4} alignItems={'stretch'}>
        <Flex
          ml={isExpanded ? 6 : 2}
          mr={2}
          mb={2}
          mt={6}
          justify={isExpanded ? 'normal' : 'center'}
        >
          <Link to="/">
            {isExpanded ? <ValidMindLogo /> : <ValidMindVLogo />}
          </Link>
        </Flex>
        {menuItems.top.length && (
          <Stack gap={0}>
            {menuItems.top.map(item => (
              <MenuItem
                key={item.key}
                itemKey={item.key}
                label={item.label}
                icon={item.icon}
                path={item.path}
                submenuItems={item.submenuItems}
              />
            ))}
          </Stack>
        )}
      </VStack>

      <VStack gap={0.5} alignItems={'stretch'}>
        {menuItems.bottom.map(item => (
          <MenuItem
            key={item.key}
            itemKey={item.key}
            label={item.label}
            icon={item.icon}
            path={item.path}
            submenuItems={item.submenuItems}
            secondaryIcon={item.secondaryIcon}
          />
        ))}
        <Divider />
        <Box py={3} pl={isExpanded ? 5 : 5}>
          <UserDisplay
            isExpanded={isExpanded}
            moreThanOneOrg={userHasMoreThanOneOrg() ?? false}
          />
        </Box>

        <Divider
          borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        />

        <Flex
          onClick={handleToggle}
          w="full"
          alignItems={'center'}
          cursor={'pointer'}
          py={2}
          px={isExpanded ? 0 : 2}
          justify={isExpanded ? 'flex-start' : 'center'}
        >
          {isExpanded ? (
            <Button
              w={'fit-content'}
              variant={'ghost'}
              onClick={handleToggle}
              leftIcon={<Icon as={ChevronDoubleLeftIcon} boxSize={5} />}
            >
              Collapse
            </Button>
          ) : (
            <Tooltip
              label={'Expand'}
              placement="right"
              openDelay={400}
              hasArrow={false}
              gutter={-1}
              bg={useColorModeValue('neutral.100', 'neutral.900')}
              color={useColorModeValue('brand.dark', 'neutral.200')}
              py={3}
              roundedBottomRight={4}
              shadow={'md'}
            >
              <IconButton
                variant="ghost"
                aria-label="Expand"
                icon={
                  <Icon
                    as={ChevronDoubleRightIcon}
                    boxSize={5}
                    onClick={handleToggle}
                  />
                }
              />
            </Tooltip>
          )}
        </Flex>
      </VStack>
    </Box>
  );
};
