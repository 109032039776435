import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { TDashboardVisualizationJSON } from '../../../models/report';
import RenderVisualization from '../../Reporting/Visualizations/RenderVisualization';

export const ViewVisualizationFullScreenModal = ({
  isOpen,
  onClose,
  visualizationJSON,
}: {
  isOpen: boolean;
  onClose: () => void;
  visualizationJSON?: TDashboardVisualizationJSON;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'full'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody display="flex">
          <VStack width="full" alignItems="flex-start">
            <Box display="flex" flexGrow={0} mb={4}>
              <Heading as="h2">{visualizationJSON?.title}</Heading>
            </Box>
            {!!visualizationJSON && (
              <>
                <Box display="flex" w="full" flex={1}>
                  <RenderVisualization
                    isEditing={false}
                    visualizationJSON={visualizationJSON}
                  />
                </Box>
              </>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
