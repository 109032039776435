import { defineStyleConfig } from '@chakra-ui/react';

export const Textarea = defineStyleConfig({
  baseStyle: {
    _focus: {
      _dark: {
        background: 'neutral.850 !important',
      },
      boxShadow: '0 0 0 3px brand.400',
      outline: 'none',
    },
  },
});
