import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Tag,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { WorkflowStatusForm } from '../../models/workflow';
import { useEffect, useMemo, useState } from 'react';
import { TStatusesWorkflowStatus } from '../../models/statuses_workflow';
import { Label } from '../Layout';
import { useMutation } from 'react-query';
import API from '../../api/API';
import CustomFieldRenderer from '../NewCustomFields/CustomFieldRenderer';
import {
  convertRJSFToCustomFieldJSON,
  getSchemaPropertyByKey,
  removeAfterDot,
} from '../../utils';
import useModelSchema from '../../hooks/useModelSchema';
import useFields, { FieldValues } from '../../hooks/useFields';

function pickProperties<T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Pick<T, K> {
  return keys.reduce((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as Pick<T, K>);
}

interface TriggerMutationVariables {
  triggerId: string;
  entityCuid: string;
  action: WorkflowStatusForm;
  fieldValues: FieldValues;
  notes: string;
}

interface WorkflowStatusFormModalProps {
  triggerId: string;
  entityCuid: string;
  fromStatus?: TStatusesWorkflowStatus;
  defaultCustomFieldValues: FieldValues;
  action: WorkflowStatusForm;
  onCancel: () => void;
  onSave: () => void;
}

export default function WorkflowStatusFormModal({
  triggerId,
  entityCuid,
  fromStatus,
  defaultCustomFieldValues,
  action,
  onCancel,
  onSave,
}: WorkflowStatusFormModalProps) {
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notes, setNotes] = useState('');
  const { fieldValues, fieldErrors, setFieldValue, setFieldError } = useFields(
    pickProperties(defaultCustomFieldValues, action.required_fields),
  );
  const fieldRefs: any = {};
  useEffect(() => {
    if (action) {
      onOpen();
    } else {
      onClose();
    }
  }, [action]);

  const { data: schemaData } = useModelSchema();

  const trigger = useMutation(
    ['workflows', 'status_form', action.trigger],
    async (variables: TriggerMutationVariables) => {
      const accessToken = await getAccessTokenSilently();
      const { triggerId, entityCuid, action, fieldValues, notes } = variables;
      return API.TriggerWorkflowStatusForm(
        accessToken,
        triggerId,
        entityCuid,
        action.trigger,
        fieldValues,
        notes,
      );
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          position: 'bottom-right',
          duration: 10000,
          isClosable: true,
          description: action.status?.name,
          title: 'Status has been changed',
        });
        onSave();
        onClose();
      },
    },
  );

  const closeModal = () => {
    onCancel();
    onClose();
  };

  const onSubmit = () => {
    trigger.mutate({ triggerId, entityCuid, action, fieldValues, notes });
  };

  const requiredCustomFields = useMemo(() => {
    if (
      schemaData &&
      Object.keys(schemaData?.schema.properties || {}).length > 0
    ) {
      const requiredSchemaKeys: string[] = action.required_fields;

      return requiredSchemaKeys.map(key => {
        const [schemaProperty, settingsProperty] = getSchemaPropertyByKey(
          schemaData,
          key,
        );
        return convertRJSFToCustomFieldJSON(
          key,
          schemaProperty,
          settingsProperty,
        );
      });
    }
    return [];
  }, [schemaData]);

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={closeModal}
        size="3xl"
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent data-testid="change-status-modal">
          <ModalHeader>
            {fromStatus ? (
              <>
                Transition model from{' '}
                <Box display={'inline-flex'}>
                  <Tag
                    data-testid="status-badge"
                    colorScheme={removeAfterDot(fromStatus?.colors.primary)}
                    size="md"
                  >
                    {fromStatus?.name}
                  </Tag>
                </Box>{' '}
                to{' '}
                <Box display={'inline-flex'}>
                  <Tag
                    data-testid="status-badge"
                    colorScheme={removeAfterDot(action.status?.colors.primary)}
                    size="md"
                  >
                    {action.status?.name}
                  </Tag>
                </Box>
              </>
            ) : (
              <>
                Transition to{' '}
                <Box display={'inline-flex'}>
                  <Tag
                    data-testid="status-badge"
                    size="sm"
                    colorScheme={removeAfterDot(action.status?.colors.primary)}
                  >
                    {action.status?.name}
                  </Tag>
                </Box>
              </>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {trigger.isError && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle fontSize="sm">Could not change status:</AlertTitle>
                <AlertDescription fontSize="sm">
                  {API.getAPIErrorMessage(trigger.error)}
                </AlertDescription>
              </Alert>
            )}
            <Stack gap={8}>
              {requiredCustomFields.length > 0 && (
                <Box>
                  <SimpleGrid columns={1} spacing={'8'}>
                    {requiredCustomFields.map(customField => {
                      const fieldValue = fieldValues[customField.key];
                      const fieldError = fieldErrors[customField.key];
                      return (
                        <Box key={customField.key}>
                          <Label mb={2}>{customField.label}</Label>
                          <CustomFieldRenderer
                            ref={ref => {
                              fieldRefs[customField.key] = ref;
                            }}
                            schema={customField}
                            value={fieldValue}
                            onChange={value => {
                              setFieldValue(customField.key, value);
                            }}
                            mode="edit"
                            onError={errors => {
                              setFieldError(customField.key, errors);
                            }}
                          />
                          {fieldError && fieldError.length > 0 && (
                            <Text fontSize="small" color="red.500">
                              {fieldError[0]}
                            </Text>
                          )}
                        </Box>
                      );
                    })}
                  </SimpleGrid>
                </Box>
              )}
              <Box>
                <FormControl>
                  <Label mb={2}>NOTES:</Label>
                  <Textarea
                    placeholder={'Add details about the transition here...'}
                    value={notes}
                    onChange={event => setNotes(event.target.value)}
                    rows={6}
                  ></Textarea>
                </FormControl>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={closeModal}
              disabled={trigger.isLoading}
              data-testid="change-status-modal-cancel-btn"
              variant={'ghost'}
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              onClick={onSubmit}
              variant={'primary'}
              data-testid="change-status-modal-submit-btn"
            >
              {trigger.isLoading ? 'Saving...' : 'Submit'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
