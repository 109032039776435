import { useCallback } from 'react';
import { TDashboardVisualizationJSON } from '../../../models/report';
import ReportBarChart from './ReportBarChart';
import ReportCounter from './ReportCounter';
import ReportPieChart from './ReportPieChart';
import { createSearchParams, useNavigate } from 'react-router-dom';

type Props = {
  isEditing: boolean;
  visualizationJSON: TDashboardVisualizationJSON;
};

export default function RenderVisualization({
  isEditing,
  visualizationJSON,
}: Props) {
  const navigate = useNavigate();

  const onChartClick = useCallback(
    (param: any, data: any[]) => {
      // We don't want the chart to be clickable when editing
      if (isEditing) {
        return;
      }
      const { seriesName, name, componentType } = param;

      const datasetUrlMap: any = {
        models: '/model-inventory',
        findings: '/model-findings',
      };

      let record;

      if (componentType === 'series') {
        record = data.find(d => {
          if (d.group.length === 1) {
            return d.group[0] === name;
          }

          return d.group[0] === name && d.group[1] === seriesName;
        });
      }

      // For counters, we set the data to the ids already
      if (componentType === 'counter') {
        record = data;
      }

      if (record) {
        navigate({
          pathname: datasetUrlMap[visualizationJSON.dataset],
          search: createSearchParams({
            filters: JSON.stringify({ cuids: record.ids.split(',') }),
          }).toString(),
        });
      }
    },
    [visualizationJSON.dataset, isEditing, navigate],
  );

  if (visualizationJSON.type === 'bar-chart') {
    return (
      <ReportBarChart
        chartClick={onChartClick}
        visualizationJSON={visualizationJSON}
      />
    );
  }

  if (visualizationJSON.type === 'pie-chart') {
    return (
      <ReportPieChart
        chartClick={onChartClick}
        visualizationJSON={visualizationJSON}
      />
    );
  }

  if (visualizationJSON.type === 'counter') {
    return (
      <ReportCounter
        visualizationJSON={visualizationJSON}
        chartClick={onChartClick}
      />
    );
  }

  return null;
}
