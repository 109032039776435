import { TDashboardVisualizationJSON } from '../../../../models/report';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import API from '../../../../api/API';
import { Box, Spinner, VStack } from '@chakra-ui/react';
import ScalableText from '../../../ScaleableText';

type Props = {
  visualizationJSON: TDashboardVisualizationJSON;
  chartClick: (params: any, data: any[]) => void;
};

const ReportCounter = ({
  visualizationJSON: { dataset, metrics = [], filtering, extraSettings },
  chartClick,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!dataset || metrics.length === 0) {
        return;
      }
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const results = await API.GetReportingData(
        accessToken,
        dataset,
        metrics,
        undefined,
        undefined,
        filtering,
      );
      setData(results);
      setLoading(false);
    })();
  }, [dataset, metrics, filtering]);

  if (loading) {
    return (
      <Box
        display="flex"
        flex={1}
        h="full"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <VStack
      onClick={() => {
        if (data && data[0]) {
          chartClick(
            {
              componentType: 'counter',
            },
            data[0],
          );
        }
      }}
      cursor={'pointer'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="100%"
      w="100%"
      color={extraSettings?.valueColor || 'neutral.500'}
      fontWeight={'bold'}
    >
      <ScalableText>{data && data[0]?.metric}</ScalableText>
    </VStack>
  );
};

export default ReportCounter;
