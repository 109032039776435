import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  MarkerType,
  MiniMap,
  Node,
  OnEdgesChange,
  OnNodesChange,
  Panel,
  ReactFlowInstance,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ApprovalNode from '../nodes/ApprovalNode';
import StatusFormNode from '../nodes/StatusFormNode';
import StatusSetNode from '../nodes/StatusSetNode';
import StartNode from '../nodes/StartNode';
import { upsertNode } from '../utils';
import _, { forEach } from 'lodash';
import NodeSettings from '../Panels/NodeSettings';
import NodesBar from '../Panels/NodesBar';
import ApprovalPanel from '../Panels/ApprovalPanel';
import StatusSetPanel from '../Panels/StatusSetPanel';
import StatusFormPanel from '../Panels/StatusFormPanel';
import { ContentPageTitle } from '../../../../../components/Layout';
import FloatingEdge from '../edges/FloatingEdge';
import FloatingConnectionLine from '../edges/FloatingEdge/FloatingConnectionLine';
import useWorkflow from '../../../../../hooks/useWorkflow';
import UsersContext from '../../../../../contexts/UsersContext';
import BranchRouterNode from '../nodes/BranchNodes/BranchRouterNode';
import BranchNodePanel from '../Panels/BranchPanel';
import BranchRouteNode from '../nodes/BranchNodes/BranchRouteNode';
import { WorkflowSource } from '../../../../../models/workflow';
import { BranchRouteNodeType } from '../types';
import WaitNode from '../nodes/WaitNode';
import WaitNodePanel from '../Panels/WaitPanel';

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

export const nodeTypes = {
  [ApprovalNode.type]: ApprovalNode,
  [StatusFormNode.type]: StatusFormNode,
  [StatusSetNode.type]: StatusSetNode,
  [StartNode.type]: StartNode,
  [BranchRouterNode.type]: BranchRouterNode,
  [BranchRouteNode.type]: BranchRouteNode,
  [WaitNode.type]: WaitNode,
};

export const edgeTypes = {
  floating: FloatingEdge,
};

export const panels = {
  [StatusSetNode.type]: StatusSetPanel,
  [StatusFormNode.type]: StatusFormPanel,
  [ApprovalNode.type]: ApprovalPanel,
  [BranchRouterNode.type]: BranchNodePanel,
  [WaitNode.type]: WaitNodePanel,
};

export default function WorkflowCanvas() {
  return (
    <ReactFlowProvider>
      <Canvas />
    </ReactFlowProvider>
  );
}

const Canvas = () => {
  const toast = useToast();
  const { deleteElements, getNode, getNodes, getEdges } = useReactFlow();
  const { workflow, saveSource, setSelectedNodeId } = useWorkflow();
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { userHasPermission } = useContext(UsersContext);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setNodes(workflow?.version.source.nodes || []);
    setEdges(workflow?.version.source.edges || []);
  }, [workflow?.version.source]);

  // when the user connects two nodes
  const onConnect = useCallback(
    (params: Connection) => {
      const sourceNode = nodes.find(node => node.id === params.source)!;
      const targetNode = nodes.find(node => node.id === params.target)!;

      // validate connections first:
      // ApprovalNode can link only with StatusSetNode nodes
      if (
        sourceNode.type === ApprovalNode.type &&
        targetNode.type !== StatusSetNode.type
      ) {
        toast({
          title: 'Workflow Validation - Approvals',
          description:
            'Approval action results can only connect to status changes',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      // ApprovalNode sources can connect max with two targets
      if (
        sourceNode.type === ApprovalNode.type &&
        edges.filter(edge => edge.source === params.source).length >= 2
      ) {
        toast({
          title: 'Workflow Validation - Approvals',
          description:
            'Approval action results can only connect to two outcomes',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // continue defining the edge parameters based on source and target nodes

      // define the edge parameters based on source and target nodes
      const edgeParams: any = {
        ...params,
        // animated: nodeTypes[targetNode!.type!].autoRuns,
        // type: 'floating',
        label:
          sourceNode.type === ApprovalNode.type
            ? `when ${params.sourceHandle}`
            : undefined,
      };

      // set the on_approved or on_rejected callback to the target node
      let clonedNode = _.cloneDeep(sourceNode);
      if (params.sourceHandle === 'approved') {
        clonedNode.data.state_callbacks.on_enter[0].args.on_approved =
          params.target;
      } else if (params.sourceHandle === 'rejected') {
        clonedNode.data.state_callbacks.on_enter[0].args.on_rejected =
          params.target;
      }

      setNodes(currentNodes => upsertNode(currentNodes, clonedNode));
      setEdges(eds => addEdge(edgeParams, eds));
    },
    [setEdges, edges, nodes],
  );

  // listener that triggers after the edge has already been removed.
  const onEdgesDelete = useCallback(
    (edges: Edge[]) => {
      edges.forEach(edge => {
        const sourceNode = nodes.find(node => node.id === edge.source)!;
        const targetNode = nodes.find(node => node.id === edge.target)!;

        // for approval nodes edges delete, remove the on_approved or on_rejected callback from the source node
        if (edge.sourceHandle === 'approved') {
          const clonedNode = _.cloneDeep(sourceNode);
          clonedNode.data.state_callbacks.on_enter[0].args.on_approved = null;
          setNodes(currentNodes => upsertNode(currentNodes, clonedNode));
        } else if (edge.sourceHandle === 'rejected') {
          const clonedNode = _.cloneDeep(sourceNode);
          clonedNode.data.state_callbacks.on_enter[0].args.on_rejected = null;
          setNodes(currentNodes => upsertNode(currentNodes, clonedNode));
        }
      });
    },
    [edges, setEdges],
  );

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        console.error('Invalid dropped element', event);
        return;
      }

      const position = reactFlowInstance!.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      if (nodeTypes[type]) {
        const node = nodeTypes[type].getDefaultNode();
        const newNode = {
          ...node,
          position,
        };

        setNodes(nds => nds.concat(newNode));
      } else {
        console.error(`Missing '${type}' mapping on the nodeTypes object`);
      }
    },
    [reactFlowInstance],
  );

  const onAddNode = (node: Node) => {
    setNodes(currentNodes => upsertNode(currentNodes, node));
  };

  const onAddEdge = (edge: Edge) => {
    setEdges(currentEdges => addEdge(edge, currentEdges));
  };

  // Before calling the original `onNodesChange`, process the changes as needed
  const handleNodesChange: OnNodesChange = changes => {
    if (changes[0].type === 'select' && changes[0].selected === false) {
      setEdges(edges =>
        edges.map(edge => {
          return {
            ...edge,
            style: {
              ...edge.style,
              strokeWidth: 1,
              strokeDasharray: '0',
              stroke: 'var(--chakra-colors-neutral-500)',
            },
            animated: false,
            className: '',
          };
        }),
      );
    }
    const processedChanges = changes.map(change => {
      return change;
    });
    // Call the original `onNodesChange` with the processed changes
    onNodesChange(processedChanges);
  };

  // Before calling the original `onEdgesChange`, process the changes as needed
  const handleEdgesChange: OnEdgesChange = changes => {
    const processedChanges = changes.map(change => {
      return change;
    });
    // Call the original `onEdgesChange` with the processed changes
    onEdgesChange(changes);
  };

  // when the user clicks on the node's trash can icon
  const onDeleteNodeButtonPressed = useCallback(
    (node: Node) => {
      setSelectedNodeId!();
      deleteElements({ nodes: [node] });
    },
    [setNodes, setEdges],
  );

  const errors = useMemo(() => {
    const errors: string[] = [];
    nodes.forEach(node => {
      if (node.type === 'branch_route') {
        const isConnected = edges.some(edge => edge.source === node.id);

        if (!isConnected) {
          const n = node as BranchRouteNodeType;
          errors.push(
            `Conditional branch '${n.data.branch.name}' is not connected.`,
          );
        }
      }
    });

    // removes errors from display if there are no errors
    if (errors.length === 0) {
      setShowErrors(false);
    }

    return errors;
  }, [nodes, edges]);

  const canUpdateWorkflow = userHasPermission(['update_workflow'], 'all');

  const onSave = () => {
    if (errors.length > 0) {
      setShowErrors(true);
      errors.map(error => {
        toast({
          title: 'Workflow Validation',
          description: error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
    } else {
      saveSource?.mutate({ nodes, edges } as WorkflowSource);
    }
  };

  // listener that triggers after the node has already been removed.
  const onNodesDelete = (nodes: Node[]) => {
    // Get branch router nodes that are being deleted
    const routers = nodes.filter(n =>
      n.id.startsWith(`${BranchRouterNode.type}_`),
    );

    // when deleting a router node, also delete the corresponding route nodes and edges
    forEach(routers, router => {
      const routes = getNodes().filter(
        n => n.data?.branch?.router_id === router.id,
      );

      forEach(routes, route => {
        if (route.deletable === false) {
          const deletable = { ...route, deletable: true };
          // this will require a timeout later
          setNodes(nds => upsertNode(nds, deletable));
        }
      });

      const edges = getEdges().filter(
        edge => edge.source !== router.id && edge.target !== router.id,
      );
      setEdges(edges);

      setTimeout(() => {
        deleteElements({ nodes: routes });
      }, 200);
    });

    const routes = nodes.filter(n =>
      n.id.startsWith(`${BranchRouteNode.type}_`),
    );

    // when deleting a route node, also delete the corresponding router node branch data and edges
    forEach(routes, route => {
      const routerId = getNode(route.id)?.data.branch.router_id;
      if (routerId) {
        const routerNode = getNode(routerId);
        if (routerNode) {
          const branchId = route.id;
          routerNode.data.state_callbacks.on_enter[0].args.branches =
            routerNode.data.state_callbacks.on_enter[0].args.branches.filter(
              (b: any) => b.trigger_id !== branchId,
            );

          const edges = getEdges().filter(edge => {
            return edge.source !== route.id && edge.target !== route.id;
          });
          setEdges(edges);
          setNodes(nds => upsertNode(nds, routerNode));
        }
      }
    });
  };

  return (
    <>
      <ReactFlow
        onInit={instance => setReactFlowInstance(instance)}
        nodes={nodes}
        edges={edges}
        onConnect={onConnect}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onEdgesDelete={onEdgesDelete}
        onNodesDelete={onNodesDelete}
        onDrop={onDrop}
        onDragOver={onDragOver}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        minZoom={0.3}
        maxZoom={1}
        connectionLineComponent={FloatingConnectionLine}
        fitView={true}
        fitViewOptions={{ padding: 1 }}
        defaultEdgeOptions={{
          type: 'floating',
          markerEnd: { type: MarkerType.ArrowClosed, width: 12, height: 12 },
          markerStart: {
            type: MarkerType.ArrowClosed,
            orient: 'auto',
            width: 12,
            height: 12,
          },
          style: { strokeWidth: 2, stroke: 'var(--chakra-colors-neutral-400)' },
        }}
      >
        <Background />
        <MiniMap position="bottom-left" />
        <Controls position="bottom-right" />

        <Panel position={'top-left'}>
          <HStack mt={9} alignItems={'flex-end'}>
            <Box>
              <Breadcrumb color="neutral.500">
                <BreadcrumbItem>
                  <BreadcrumbLink href="/settings">Settings</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink href="/settings/workflows">
                    Workflows
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              <ContentPageTitle>
                {workflow?.title} (v{workflow?.version.number})
              </ContentPageTitle>
            </Box>
            {canUpdateWorkflow && (
              <Button isLoading={saveSource?.isLoading} onClick={onSave}>
                Save Workflow
              </Button>
            )}
          </HStack>
        </Panel>

        {showErrors && (
          <Panel position={'top-right'}>
            <Stack>
              {errors.map((error, index) => (
                <Alert status="error" fontSize="sm">
                  <AlertIcon />
                  <Text>{error}</Text>
                </Alert>
              ))}
            </Stack>
          </Panel>
        )}

        {canUpdateWorkflow && (
          <Panel position={'bottom-center'}>
            <NodesBar />
          </Panel>
        )}
        <Panel position={'top-left'}>
          <Box mt={28}>
            <NodeSettings
              onAddNode={onAddNode}
              onDeleteNode={onDeleteNodeButtonPressed}
              onAddEdge={onAddEdge}
            />
          </Box>
        </Panel>
      </ReactFlow>
    </>
  );
};
