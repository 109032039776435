import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  CloseButton,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tag,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import {
  convertToSectionTree,
  TemplateSectionContents,
  TemplateSectionTree,
} from '../../../../models/template';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
import { AssessmentEvidence } from '../../../../models/guideline';
import {
  ReflexContainer,
  ReflexElement,
  ReflexHandle,
  ReflexSplitter,
} from 'react-reflex';
import { TestResultContent } from '../TestResultContent';
import { MetadataContentEditor } from '../MetadataContentEditor';
import ContentRow from '../../../ContentRow';
import InventoryModelContext from '../../../../contexts/InventoryModel';

interface RiskAssessmentAddEvidenceModal2Props {
  isOpen: boolean;
  onClose: () => void;
  evidences: AssessmentEvidence[];
  onSelectedEvidence: (
    documentType: ModelDocumentTypeEnum,
    contents: TemplateSectionContents[],
  ) => void;
}
export default function RiskAssessmentAddEvidenceModal2({
  isOpen,
  onClose,
  evidences,
  onSelectedEvidence,
}: RiskAssessmentAddEvidenceModal2Props) {
  const { templates } = useContext(InventoryModelContext);
  const tree = convertToSectionTree(templates.documentation.sections);
  const [selected, setSelected] = useState<TemplateSectionContents[]>([]);
  const [previewContent, setPreviewContent] =
    useState<TemplateSectionContents | null>(null);
  const DragHandleIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 8.99998C16.5 8.17155 17.1716 7.49997 18 7.49997C18.8284 7.49997 19.5 8.17155 19.5 8.99998C19.5 9.82841 18.8284 10.5 18 10.5C17.1716 10.5 16.5 9.82841 16.5 8.99998ZM10.5 8.99998C10.5 8.17155 11.1715 7.49997 12 7.49997C12.8284 7.49997 13.5 8.17155 13.5 8.99998C13.5 9.82841 12.8284 10.5 12 10.5C11.1715 10.5 10.5 9.82841 10.5 8.99998ZM4.49992 8.99998C4.49992 8.17155 5.17149 7.49997 5.99992 7.49997C6.82836 7.49997 7.49993 8.17155 7.49993 8.99998C7.49993 9.82841 6.82836 10.5 5.99992 10.5C5.17149 10.5 4.49992 9.82841 4.49992 8.99998ZM16.5 15C16.5 14.1716 17.1716 13.5 18 13.5C18.8284 13.5 19.5 14.1716 19.5 15C19.5 15.8284 18.8284 16.5 18 16.5C17.1716 16.5 16.5 15.8284 16.5 15ZM10.5 15C10.5 14.1716 11.1715 13.5 12 13.5C12.8284 13.5 13.5 14.1716 13.5 15C13.5 15.8284 12.8284 16.5 12 16.5C11.1715 16.5 10.5 15.8284 10.5 15ZM4.49992 15C4.49992 14.1716 5.17149 13.5 5.99992 13.5C6.82836 13.5 7.49993 14.1716 7.49993 15C7.49993 15.8284 6.82836 16.5 5.99992 16.5C5.17149 16.5 4.49992 15.8284 4.49992 15Z"
        fill="#111313"
      />
    </svg>
  );

  useEffect(() => {
    setSelected(
      evidences.map(evidence => {
        return {
          content_id: evidence.evidence_content_id,
          content_type: evidence.evidence_content_type,
        };
      }),
    );
  }, [evidences]);

  const onClickPreviewEvidence = (
    index: number,
    item: TemplateSectionTree,
    content: TemplateSectionContents,
    parent?: TemplateSectionTree,
  ) => {
    setPreviewContent(
      previewContent?.content_id == content.content_id ? null : content,
    );
  };

  const toggleCheck = (content: TemplateSectionContents) => {
    setSelected(prevSelected => {
      const exists = prevSelected.some(
        item =>
          item.content_id === content.content_id &&
          item.content_type === content.content_type,
      );

      if (exists) {
        // Remove content if it exists
        return prevSelected.filter(
          item =>
            !(
              item.content_id === content.content_id &&
              item.content_type === content.content_type
            ),
        );
      } else {
        // Add content if it doesn't exist
        return [...prevSelected, content];
      }
    });
  };

  const onInsert = () => {
    onSelectedEvidence(ModelDocumentTypeEnum.model_documentation, selected);
    onClose();
  };

  const renderTree = (
    items: TemplateSectionTree[],
    indices: number[],
    parentSection?: TemplateSectionTree,
  ) => {
    return items.map((item: TemplateSectionTree, index: number) => {
      const newIndices = [...indices, index + 1];
      if (!item.sections || item.sections.length === 0) {
        // contents for section with no content is a text block with its content id
        const contents = item.contents || [];
        if (contents.length === 0) {
          const section = {
            content_type: 'text',
            content_id: item.id,
          } as TemplateSectionContents;
          contents.push(section);
        }

        // check contents that are already selected
        const matchingContents = contents.filter(content =>
          selected.some(
            e =>
              e.content_id === content.content_id &&
              e.content_type === content.content_type,
          ),
        );

        // master check is checked if all its contents are checked
        const sectionIsChecked = matchingContents.length === contents.length;
        // or master checker is indeterminate if some of its contents are checked
        const sectionIsCheckedIndeterminate =
          !sectionIsChecked && matchingContents.length > 0;

        return (
          <>
            <Accordion allowMultiple>
              <AccordionItem>
                {({ isExpanded }) => (
                  <>
                    <HStack gap={0} position="relative">
                      <Center p={4} position={'absolute'} zIndex={10}>
                        <Checkbox
                          isChecked={sectionIsChecked}
                          isIndeterminate={sectionIsCheckedIndeterminate}
                          onChange={() => {
                            // handle master checkbox
                            if (sectionIsChecked) {
                              // uncheck all when entire section is checked
                              matchingContents.map(content =>
                                toggleCheck(content),
                              );
                            } else {
                              // check all not checked
                              contents
                                .filter(
                                  content =>
                                    !selected.some(
                                      e =>
                                        e.content_id === content.content_id &&
                                        e.content_type === content.content_type,
                                    ),
                                )
                                .map(content => toggleCheck(content));
                            }
                          }}
                        />
                      </Center>

                      <AccordionButton
                        as={Button}
                        variant={'ghost'}
                        rounded={0}
                      >
                        <HStack
                          alignItems={'baseline'}
                          justifyContent={'flex-start'}
                          pl={8}
                        >
                          <Text
                            fontFamily={'monospace'}
                            fontSize={'xs'}
                            fontWeight={'normal'}
                          >
                            {newIndices.join('.')}.{' '}
                          </Text>
                          <Text>{item.title}</Text>
                          {matchingContents.length > 0 && (
                            <Tag
                              colorScheme="brand"
                              bg={useColorModeValue('brand.25', 'brand.950')}
                            >
                              {matchingContents.length} block
                              {matchingContents.length === 1 ? '' : 's'}{' '}
                              selected
                            </Tag>
                          )}
                        </HStack>
                        <Spacer />
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <AccordionIcon />
                        </Box>
                      </AccordionButton>
                    </HStack>
                    <Stack position={'relative'} overflow={'hidden'}>
                      <Box position={'absolute'} right={0}></Box>
                    </Stack>
                    <AccordionPanel pt={2} pl={0} pr={0}>
                      <Stack gap={0} ml={4}>
                        <Heading as={'h5'} mb={4}>
                          Blocks in section
                        </Heading>
                        {contents.map((content, index) => {
                          const contentIsChecked = selected.some(
                            e =>
                              e.content_id === content.content_id &&
                              e.content_type === content.content_type,
                          );
                          const isActive =
                            previewContent?.content_id === content.content_id;
                          return (
                            <Button
                              py={4}
                              w={'full'}
                              rounded={0}
                              border={'none'}
                              variant={'ghost'}
                              borderTop={'1px solid'}
                              onClick={() =>
                                onClickPreviewEvidence(
                                  index,
                                  item,
                                  content,
                                  parentSection,
                                )
                              }
                              borderColor={useColorModeValue(
                                'neutral.200',
                                'neutral.800',
                              )}
                              borderLeft={
                                '1px solid var(--chakra-colors-chakra-border-color)'
                              }
                              bg={isActive ? 'brand.25' : 'transparent'}
                              zIndex={isActive ? '1000' : 'initial'}
                              _last={{
                                borderBottom:
                                  '1px solid var(--chakra-colors-chakra-border-color)',
                              }}
                            >
                              <HStack w={'full'}>
                                <ContentRow
                                  documentType={
                                    ModelDocumentTypeEnum.model_documentation
                                  }
                                  content={content}
                                  isChecked={contentIsChecked}
                                  onCheck={item => toggleCheck(item)}
                                />
                              </HStack>
                            </Button>
                          );
                        })}
                      </Stack>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </>
        );
      } else {
        return (
          <VStack
            gap={'1px'}
            borderTop={'1px solid'}
            alignItems={'flex-start'}
            borderColor={useColorModeValue('neutral.200', 'neutral.800')}
          >
            <Box
              py={3}
              px={4}
              mb={0}
              w={'full'}
              rounded={0}
              border={'none'}
              fontWeight={'semi-bold'}
              borderColor={'var(--chakra-colors-chakra-border-color)'}
              transition={'all 0.2s ease-in-out'}
            >
              <HStack w={'full'} alignItems={'baseline'} gap={1}>
                <Text fontFamily={'monospace'} fontSize={'xs'}>
                  {`${newIndices.join('.')}.`}
                </Text>
                <Text>{item.title}</Text>
                <Spacer />
              </HStack>
            </Box>
            {item.sections && item.sections.length > 0 ? (
              <Box
                ml={5}
                w={'calc(100% - 1.2rem)'}
                borderLeft={
                  item.sections && item.sections.length > 0 ? 'solid' : 'none'
                }
                borderColor={'var(--chakra-colors-chakra-border-color)'}
              >
                {renderTree(item.sections, newIndices, item)}
              </Box>
            ) : null}
          </VStack>
        );
      }
    });
  };

  const close = () => {
    setPreviewContent(null);
    onClose();
  };

  return (
    <Modal
      isCentered
      size={'6xl'}
      isOpen={isOpen}
      onClose={close}
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Link Developer Evidence to Validation Report</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Box height={'75vh'}>
            <ReflexContainer orientation="horizontal">
              <ReflexElement minSize={96}>
                <Box
                  data-testid="model-documentation-outline"
                  w={'calc(100% - 4px)'}
                >
                  {renderTree(tree, [])}
                </Box>
              </ReflexElement>

              {previewContent && <ReflexSplitter propagate={true} />}
              {previewContent && (
                <ReflexElement minSize={96}>
                  <ReflexHandle>
                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      _hover={{
                        bg: useColorModeValue(
                          'brandSecondary.25',
                          'brandSecondary.950',
                        ),
                      }}
                    >
                      {DragHandleIcon}
                    </Box>
                  </ReflexHandle>

                  <VStack
                    border={
                      '1px solid var(--chakra-colors-chakra-border-color)'
                    }
                    rounded={'md'}
                    p={4}
                  >
                    <Stack alignItems={'flex-end'} w={'full'}>
                      <CloseButton onClick={() => setPreviewContent(null)} />
                    </Stack>

                    {previewContent.content_type === 'test' && (
                      <TestResultContent
                        readOnly={true}
                        contents={{
                          content_id: previewContent.content_id,
                          content_type: previewContent.content_type,
                        }}
                        documentType={ModelDocumentTypeEnum.model_documentation}
                        overrideDocumentType={
                          ModelDocumentTypeEnum.model_documentation
                        }
                      />
                    )}

                    {['text', 'metadata_text'].includes(
                      previewContent.content_type,
                    ) && (
                      <MetadataContentEditor
                        readOnly={true}
                        contents={{
                          content_id: previewContent.content_id,
                          content_type: previewContent.content_type,
                        }}
                        documentType={ModelDocumentTypeEnum.model_documentation}
                        overrideDocumentType={
                          ModelDocumentTypeEnum.model_documentation
                        }
                      />
                    )}
                  </VStack>
                </ReflexElement>
              )}
            </ReflexContainer>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button variant={'ghost'} onClick={close}>
            Cancel
          </Button>
          <Spacer />

          <Button variant={'primary'} isDisabled={false} onClick={onInsert}>
            Update Linked Evidence
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
