import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react';
import { NodeProps, useReactFlow, useStore } from 'reactflow';
import {
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import ConfirmationAlert from '../../../../../../components/ConfirmationAlert';
import './styles.css';

interface NodeBoxProps extends BoxProps {
  nodeProps: NodeProps;
  title?: string;
  icon?: React.ComponentType;
  children: React.ReactNode;
  errors?: string[];
}
const zoomSelector = (s: any) => s.transform[2] >= 0.7;

const NodeBox = ({
  title,
  icon,
  errors = [],
  children,
  nodeProps,
  ...rest
}: NodeBoxProps) => {
  const workflowHook = useWorkflow();
  const { selectedNodeId, setSelectedNodeId } = workflowHook;
  const { deleteElements } = useReactFlow();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const isBuilder = !nodeProps.data?.execution;

  React.useEffect(() => {
    if (!selectedNodeId) {
      resetEdges(nodeProps);
    }
  }, [selectedNodeId]);

  const showContent = useStore(zoomSelector);
  const { getEdges, setEdges } = useReactFlow();
  const onDelete = (confirmed: boolean) => {
    if (confirmed) {
      deleteElements({ nodes: [{ id: nodeProps.id }] });
    }
    setShowDeleteConfirm(false);
  };
  const edgesConnectedToSource = getEdges().filter(
    edge => edge.source === nodeProps.id,
  );
  const colorImmediateEdges = (color: string, nodeProps: NodeProps) => {
    setEdges(edges =>
      edges.map(edge => {
        if (edge.source === nodeProps.id) {
          return {
            ...edge,
            style: {
              ...edge.style,
              strokeWidth: showContent ? 2 : 3,
              stroke: color,
              strokeDasharray: '20,5',
              animation: 'flowAnimation 5s linear infinite',
            },
            animated: true,
            className: 'flowing-edge',
          };
        }
        if (edge.target === nodeProps.id) {
          return {
            ...edge,
            style: {
              ...edge.style,
              strokeWidth: showContent ? 2 : 3,
              stroke: color,
              strokeDasharray: '20,5',
              animation: 'flowAnimation 5s linear infinite',
            },
            animated: true,
            className: 'flowing-edge',
          };
        }
        return edge;
      }),
    );
  };
  const resetEdges = (nodeProps: NodeProps) => {
    setEdges(edges =>
      edges.map(edge => {
        if (edge.source === selectedNodeId || edge.target === selectedNodeId) {
          return edge;
        }
        return {
          ...edge,
          style: {
            ...edge.style,
            strokeWidth: 1,

            strokeDasharray: '0',
            stroke: 'var(--chakra-colors-neutral-500)',
          },
          animated: false,
          className: '',
        };
      }),
    );
  };
  return (
    <>
      <Box
        onDoubleClick={() => setSelectedNodeId!(nodeProps.id)}
        rounded={'sm'}
        {...rest} // Spread the rest of the props here
        bg={useColorModeValue('white', 'neutral.950')}
        border={nodeProps.selected ? 2 : 1}
        borderColor={
          nodeProps.selected
            ? 'brand.base'
            : useColorModeValue('neutral.200', 'neutral.700')
        }
        boxShadow={useColorModeValue(
          '0px 0px 0px 4px var(--chakra-colors-neutral-100)',
          '0px 0px 0px 4px var(--chakra-colors-neutral-950)',
        )}
        role={'group'}
        onMouseEnter={() => {
          colorImmediateEdges('var(--chakra-colors-neutral-500)', nodeProps);
        }}
        onMouseLeave={() => {
          if (selectedNodeId === nodeProps.id) return;
          resetEdges(nodeProps);
        }}
      >
        {(icon || title) && showContent && (
          <HStack alignItems={'flex-start'}>
            <HStack
              w={'full'}
              borderBottom={
                '1px solid var(--chakra-colors-chakra-border-color)'
              }
              gap={0}
            >
              {icon && (
                <Box p={1} mt={1}>
                  <Icon as={icon} boxSize={4} color={'inherit'} />
                </Box>
              )}
              {title && (
                <Heading as={'h6'} fontWeight={'bold'}>
                  {/* TODO: support validation report */}
                  {title}
                </Heading>
              )}

              {isBuilder && (
                <>
                  <Spacer />
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                      variant="ghost"
                      size={'sm'}
                      visibility={'hidden'}
                      _groupHover={{ visibility: 'visible' }}
                    />
                    <MenuList>
                      <MenuItem
                        icon={<Icon as={Cog6ToothIcon} boxSize={4} />}
                        onClick={() => setSelectedNodeId!(nodeProps.id)}
                      >
                        Configure
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        icon={<Icon as={TrashIcon} boxSize={4} />}
                        _hover={{
                          bg: 'red.500',
                          color: 'white',
                        }}
                        onClick={() => setShowDeleteConfirm(true)}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
            </HStack>
          </HStack>
        )}

        {children}
      </Box>
      <ConfirmationAlert
        title={`Deleting step`}
        dialogBody={`Are you sure you want to delete this step?`}
        open={showDeleteConfirm}
        onConfirm={onDelete}
      />
    </>
  );
};

export default NodeBox;
