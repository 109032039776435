import { useContext } from 'react';
import WorkflowContext from '../../contexts/WorkflowContext';

// This function is a hook that uses the WorkflowContext to provide the workflow and saveSource
function useWorkflow() {
  const context = useContext(WorkflowContext);
  if (context === undefined) {
    throw new Error('useWorkflow must be used within a WorkflowProvider');
  }

  return context;
}

export default useWorkflow;
