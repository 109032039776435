import { Accordion, As, Box } from '@chakra-ui/react';
import { Guideline } from '../../../../models/guideline';
import { TemplateSectionContents } from '../../../../models/template';
import ContentAccordion, { ContentAccordionProps } from './ContentAccordion';
import { useContext } from 'react';
import RiskAreaContext from '../../../../contexts/RiskAreaContext';
import {
  Bars3BottomLeftIcon,
  CheckBadgeIcon,
  CodeBracketSquareIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { QuestionIcon } from '@chakra-ui/icons';
import _ from 'lodash';

interface ContentsProps {
  sectionId: string;
  contents: TemplateSectionContents[];
  allGuidelines: Guideline[];
  isEditing?: boolean;
  onRemoveGuideline: (guideline: Guideline) => void;
  onRemoveAssessmentSummary: () => void;
}

export const Contents = ({
  sectionId,
  contents,
  allGuidelines,
  isEditing = false,
  onRemoveGuideline,
  onRemoveAssessmentSummary,
}: ContentsProps) => {
  const { riskAreas } = useContext(RiskAreaContext);
  return (
    <>
      <Box pl={2}>
        {contents.length > 0 && (
          <Accordion allowMultiple w={'full'}>
            {contents.map(content => {
              const contentAccordionProps: ContentAccordionProps = {
                id: 'null',
                icon: QueueListIcon,
                title: 'null',
              };

              if (content.content_type === 'guideline') {
                const guideline = allGuidelines.find(
                  g => g.content_id === content.content_id,
                );

                const riskArea = riskAreas.find(
                  item => item.cuid === guideline!.risk_area?.cuid,
                );

                contentAccordionProps.id = `${sectionId}_${guideline!.cuid}`;
                contentAccordionProps.icon = CheckBadgeIcon;
                contentAccordionProps.tagLabel = riskArea?.name;
                contentAccordionProps.title = guideline!.title;
                contentAccordionProps.deleteProps = isEditing
                  ? {
                      label: 'guideline',
                      onConfirm: () => onRemoveGuideline(guideline!),
                    }
                  : undefined;
                contentAccordionProps.children = (
                  <Box
                    className={'ck ck-content'}
                    dangerouslySetInnerHTML={{ __html: guideline!.description }}
                  />
                );
              } else if (content.content_type === 'assessment_summary') {
                contentAccordionProps.id = `${sectionId}_assessment_summary`;
                contentAccordionProps.icon = QueueListIcon;
                contentAccordionProps.title = 'Assessment Summary';
                contentAccordionProps.deleteProps = isEditing
                  ? {
                      label: 'assessment summary',
                      onConfirm: () => onRemoveAssessmentSummary(),
                    }
                  : undefined;
              } else {
                let icon: As = QuestionIcon;
                let tagLabel = _.startCase(content.content_type);

                if (content.content_type === 'test') {
                  icon = CodeBracketSquareIcon;
                  tagLabel = 'Test';
                } else if (content.content_type === 'text') {
                  icon = Bars3BottomLeftIcon;
                  tagLabel = 'Text';
                }

                contentAccordionProps.id = `${sectionId}_${content.content_id}`;
                contentAccordionProps.icon = icon;
                contentAccordionProps.title = content.content_id;
                contentAccordionProps.tagLabel = tagLabel;
              }

              return <ContentAccordion {...contentAccordionProps} />;
            })}
          </Accordion>
        )}
      </Box>
    </>
  );
};
