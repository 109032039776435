import axios from 'axios';
import { CONFIG } from '../config';
import { AuthHeaders } from './API';

// Create a custom event name
export const UNAUTHORIZED_EVENT = 'admin-unauthorized';

const axiosInstance = axios.create({
  baseURL: `${CONFIG.REACT_APP_API_URL}`,
  headers: {
    'X-Application': 'Admin',
  },
});

// Update the interceptor to emit an event instead of redirecting
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const errorData = error.response?.data;

    if (
      (error.response?.status === 400 && errorData?.code === 'unauthorized') ||
      error.response?.status === 403
    ) {
      // Emit custom event instead of redirecting
      window.dispatchEvent(new Event(UNAUTHORIZED_EVENT));
    }

    return Promise.reject(error);
  },
);

export type Role = {
  cuid: string;
  name: string;
  description: string;
  is_default_role: boolean;
  organization_cuid: string;
  is_admin?: boolean;
  is_staff?: boolean;
  scope: string;
};

export type Organization = {
  cuid: string;
  name: string;
  roles: Role[];
  created_at: string;
  updated_at: string;
};

export type AdminUser = {
  email: string;
  name: string;
  password: string;
};

export type CreateOrganizationRequest = {
  name: string;
  demo_inventory_models: string[];
  admin_user: AdminUser;
  initial_templates: string[];
};

export type DemoModel = {
  name: string;
  area: string;
  description: string;
};

export type Template = {
  name: string;
};

export type OrganizationDefaults = {
  initial_demo_models: DemoModel[];
  initial_templates: Template[];
};

export type UserRole = {
  cuid: string;
  role: Role;
  user: {
    cuid: string;
  };
  scope: string;
};

export type PostUserRequest = {
  name: string;
  last_name: string;
  email: string;
  password: string;
  organizations: {
    organization_cuid: string;
    roles: string[];
  }[];
};

export type PatchUserRequest = {
  name: string;
  last_name: string;
  password: string;
  email: string;
  organization: {
    organization_cuid: string;
    roles: string[];
  };
};

type APIClient = {
  api_key: string;
  api_secret: string;
  organization_cuid: string;
};

export type User = {
  cuid: string;
  name: string;
  last_name: string;
  job_title: string;
  picture: string;
  email: string;
  organizations: Organization[];
  roles: UserRole[];
  api_clients: APIClient[];
  accepted_tyc?: number;
  accepted_ai_terms?: number;
  created_at: string;
  updated_at: string;
};

const GetOrganizations = async (accessToken: string) => {
  const response = await axiosInstance.get<Organization[]>(
    `/admin/organizations`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

// Add the new method in the same file after GetOrganizations
const GetOrganizationDefaults = async (accessToken: string) => {
  const response = await axiosInstance.get<OrganizationDefaults>(
    `/admin/organizations/defaults`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const CreateOrganization = async (
  accessToken: string,
  organizationData: CreateOrganizationRequest,
) => {
  const response = await axiosInstance.post<Organization>(
    '/admin/organizations',
    organizationData,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetUsers = async (accessToken: string): Promise<User[]> => {
  const response = await axiosInstance.get<User[]>(`/admin/users`, {
    headers: AuthHeaders(accessToken),
  });
  return response.data;
};

const PostUser = async (
  accessToken: string,
  userData: PostUserRequest,
): Promise<User> => {
  const response = await axiosInstance.post<User>('/admin/users', userData, {
    headers: AuthHeaders(accessToken),
  });
  return response.data;
};

const PatchUser = async (
  accessToken: string,
  userId: string,
  patchData: PatchUserRequest,
): Promise<User> => {
  const response = await axiosInstance.patch<User>(
    `/admin/users/${userId}`,
    patchData,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const PatchRBAC = async (
  accessToken: string,
  action: string,
): Promise<User> => {
  const response = await axiosInstance.patch<User>(
    `/admin/rbac`,
    { action },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

export default {
  GetOrganizations,
  GetOrganizationDefaults,
  CreateOrganization,
  GetUsers,
  PostUser,
  PatchUser,
  PatchRBAC,
};
