import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../../../../api/API';

function useStatusesWorkflows() {
  const { getAccessTokenSilently } = useAuth0();

  const query = useQuery(['statuses'], async () => {
    const accessToken = await getAccessTokenSilently();
    return await API.GetStatusesWorkflows(accessToken);
  });

  return { ...query };
}

function useStatus(cuid: string) {
  const { getAccessTokenSilently } = useAuth0();

  const query = useQuery(
    ['statuses', cuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetStatusesWorkflowStatus(accessToken, cuid);
    },
    {
      enabled: !!cuid,
    },
  );

  return { ...query };
}

export { useStatusesWorkflows, useStatus };
