import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  VStack,
  PopoverHeader,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  Icon,
  MenuList,
  MenuDivider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Input,
  Textarea,
  ModalFooter,
  Spacer,
  Heading,
} from '@chakra-ui/react';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BookmarkIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { TSavedView, TSavedViewTypes } from '../../models/saved_view';
import { Label } from '../Layout';
import _ from 'lodash';

type AddOrEditViewModalProps = {
  isOpen: boolean;
  existingView?: TSavedView;
  onClose: () => void;
  onSave: ({
    id,
    name,
    description,
  }: {
    id?: string;
    name: string;
    description: string;
  }) => Promise<void>;
};

export const AddOrEditViewModal = ({
  isOpen,
  existingView,
  onClose,
  onSave,
}: AddOrEditViewModalProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    await onSave({ name, description, id: existingView?.cuid });
    setIsSubmitting(false);
    onClose();
  };

  useEffect(() => {
    if (existingView) {
      setName(existingView.name);
      setDescription(existingView.description || '');
    } else {
      setName('');
      setDescription('');
    }
  }, [existingView]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{!!existingView ? 'Edit' : 'Add New'} View</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={4}>
            <FormControl>
              <Label mb={2}>View Name</Label>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <Label mb={2}>Description</Label>
              <Textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="What will this view be about..."
              ></Textarea>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isDisabled={!name}
            isLoading={isSubmitting}
            onClick={onSubmit}
            variant={'primary'}
          >
            {!!existingView ? 'Save Changes' : 'Add New View'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ViewConfirmModal = ({
  confirmationFor,
  isOpen,
  onClose,
  onConfirm,
}: {
  confirmationFor: 'delete' | 'update';
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const cancelRef = useRef() as any;
  const title = confirmationFor === 'delete' ? 'Delete' : 'Update';
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title} View
          </AlertDialogHeader>

          <AlertDialogBody>
            {
              {
                delete:
                  'Are you sure you want to delete this view? This cannot be undone.',
                update:
                  'Are you sure you want to update this view? The current filters, sorts and visible columns configurations will be applied to this view.',
              }[confirmationFor]
            }
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={onConfirm}
              ml={3}
            >
              {title}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

type ManageViewsButtonProps = {
  viewType: TSavedViewTypes;
  onViewSelected: (savedView: TSavedView) => void;
  getCurrentContent: () => any;
  selectedView?: TSavedView;
};

const ManageViewsButton: React.FC<ManageViewsButtonProps> = ({
  viewType,
  onViewSelected,
  getCurrentContent,
  selectedView,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const viewDeleteConfirmModal = useDisclosure();
  const addOrEditViewModal = useDisclosure();
  const viewUpdateConfirmModal = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [views, setViews] = useState<TSavedView[]>([]);
  const [selectedViewForEditing, setSelectedViewForEditing] = useState<
    TSavedView | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      API.GetSavedViews(accessToken, viewType).then(v => {
        setViews(v);
      });
      setIsLoading(false);
    })();
  }, [viewType]);

  const addOrEditView = useCallback(
    async ({
      name,
      description,
      id,
    }: {
      name: string;
      description?: string;
      id?: string;
    }) => {
      const accessToken = await getAccessTokenSilently();
      if (id) {
        await API.UpdateSavedView(accessToken, id, { name, description });
      } else {
        await API.CreateSavedView(accessToken, {
          type: viewType,
          name,
          description,
          content: getCurrentContent(),
        });
      }
      const v = await API.GetSavedViews(accessToken, viewType);
      setViews(v);
    },
    [getAccessTokenSilently, getCurrentContent, viewType],
  );

  let viewWasModified = false;

  if (selectedView) {
    // Remove undefined values from the content
    const cleanedViewContent = _.omitBy(selectedView.content, _.isUndefined);

    // Remove undefined values from the current content
    const cleanedCurrentContent = _.omitBy(getCurrentContent(), _.isUndefined);

    viewWasModified = !_.isEqual(cleanedViewContent, cleanedCurrentContent);
  }

  return (
    <>
      <ViewConfirmModal
        confirmationFor="delete"
        isOpen={viewDeleteConfirmModal.isOpen}
        onClose={viewDeleteConfirmModal.onClose}
        onConfirm={async () => {
          if (selectedViewForEditing) {
            const accessToken = await getAccessTokenSilently();
            await API.DeleteSavedView(accessToken, selectedViewForEditing.cuid);
            const v = await API.GetSavedViews(accessToken, viewType);
            setViews(v);
          }
          viewDeleteConfirmModal.onClose();
        }}
      />
      <ViewConfirmModal
        confirmationFor="update"
        isOpen={viewUpdateConfirmModal.isOpen}
        onClose={viewUpdateConfirmModal.onClose}
        onConfirm={async () => {
          if (selectedView) {
            const accessToken = await getAccessTokenSilently();
            const newSavedView = await API.UpdateSavedView(
              accessToken,
              selectedView.cuid,
              {
                content: getCurrentContent(),
              },
            );
            // Update the view in the list
            const updatedViews = views.map(view =>
              view.cuid === newSavedView.cuid ? newSavedView : view,
            );
            setViews(updatedViews);
            onViewSelected(newSavedView);
          }
          viewUpdateConfirmModal.onClose();
        }}
      />
      <AddOrEditViewModal
        existingView={selectedViewForEditing}
        isOpen={addOrEditViewModal.isOpen}
        onClose={addOrEditViewModal.onClose}
        onSave={addOrEditView}
      />
      <Popover placement="bottom-start">
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant="ghost"
                isDisabled={isLoading}
                isLoading={isLoading}
                leftIcon={<Icon as={BookmarkIcon} boxSize={5} />}
                maxW={'35rem'}
                overflow={'hidden'}
                whiteSpace={'normal'}
              >
                {views?.length > 0 ? (
                  <HStack gap={0}>
                    <Text textAlign={'left'} noOfLines={1}>
                      {selectedView
                        ? `${selectedView.name}${viewWasModified ? '*' : ''}`
                        : 'Select View'}
                    </Text>
                  </HStack>
                ) : (
                  <HStack gap={0}>
                    <Text>Save View</Text>
                  </HStack>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent w={'30vw'} minW={'20rem'} maxW={'45rem'}>
              <PopoverArrow />
              <PopoverCloseButton size={'md'} />
              <PopoverHeader roundedTop={'md'}>
                <VStack alignItems={'start'} gap={1} pt={4}>
                  {selectedView?.type === 'model' ? (
                    <Heading as={'h4'}> Model Inventory Views</Heading>
                  ) : (
                    <Heading as={'h4'}> Findings Views</Heading>
                  )}
                  <Text>
                    Save your filters, sorts and visible columns configurations
                    with Views.
                  </Text>
                </VStack>
              </PopoverHeader>
              <PopoverBody shadow={'lg'} rounded={'md'}>
                <VStack gap={0.5}>
                  {views.map(view => (
                    <Button
                      mx={0.5}
                      pl={4}
                      pr={2}
                      w={'full'}
                      h={'auto'}
                      role="group"
                      variant={'outline'}
                      border={0}
                      borderColor={'brandSecondary.50'}
                      onMouseUp={() => {
                        onViewSelected(view);
                        onClose();
                      }}
                      whiteSpace={'none'}
                      textAlign={'left'}
                      isDisabled={view.cuid === selectedView?.cuid}
                    >
                      <HStack
                        w={'full'}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                      >
                        <HStack alignItems={'flex-start'}>
                          <Icon as={BookmarkIcon} boxSize={5} mt={3} />
                          <VStack alignItems={'flex-start'} py={3} gap={0}>
                            <Text>{view.name}</Text>
                            {view.description !== '' && (
                              <Text
                                fontWeight={'normal'}
                                fontSize={'sm'}
                                color={'neutral.500'}
                              >
                                {view.description}
                              </Text>
                            )}
                          </VStack>
                        </HStack>
                        <Menu>
                          <MenuButton
                            mt={2}
                            onMouseDown={e => e.stopPropagation()}
                            onMouseUp={e => e.stopPropagation()}
                            as={IconButton}
                            variant={'ghost'}
                            aria-label="Options"
                            icon={
                              <Icon as={EllipsisVerticalIcon} boxSize={6} />
                            }
                            size="sm"
                            visibility={'hidden'}
                            _groupHover={{
                              visibility: 'visible',
                            }}
                          />
                          <MenuList
                            onMouseDown={e => e.stopPropagation()}
                            onMouseUp={e => e.stopPropagation()}
                          >
                            <MenuItem
                              icon={<Icon as={PencilIcon} boxSize={4} />}
                              onClick={() => {
                                setSelectedViewForEditing(view);
                                addOrEditViewModal.onOpen();
                              }}
                            >
                              Edit View
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                              color="red.500"
                              icon={<Icon as={TrashIcon} boxSize={4} />}
                              onClick={async () => {
                                setSelectedViewForEditing(view);
                                viewDeleteConfirmModal.onOpen();
                              }}
                            >
                              Remove View
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </HStack>
                    </Button>
                  ))}
                </VStack>
                <HStack w={'full'} justifyContent={'space-between'} pt={4}>
                  {viewWasModified && (
                    <Button
                      variant={'ghost'}
                      onClick={viewUpdateConfirmModal.onOpen}
                    >
                      Update View
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setSelectedViewForEditing(undefined);
                      addOrEditViewModal.onOpen();
                    }}
                    leftIcon={<Icon as={BookmarkIcon} boxSize={5} />}
                  >
                    Save New View
                  </Button>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </>
  );
};

export default ManageViewsButton;
