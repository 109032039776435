import { useContext, useState } from 'react';
import { useTemplateEditor } from '../../hooks/useTemplateEditor';
import { Label } from '../Layout';
import { PencilIcon } from '@heroicons/react/24/outline';
import { TemplateEditorState } from '../../contexts/TemplateEditorContext';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  FormControl,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
  Button,
} from '@chakra-ui/react';
import { DiffEditor } from '@monaco-editor/react';
import { stringify } from 'yaml';
import { editorOptions } from '../TemplateEditModal';
import { useMutation } from 'react-query';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { UsersContext } from '../../contexts';

export const diffOptions = { ...editorOptions, readOnly: true, fontSize: 10 };

interface TemplateEditorPublisherProps {
  templateType: string;
  isLatestTemplateSelected: boolean;
  setShowEditVersion: (showEditVersion: boolean) => void;
}

export default function TemplateEditorPublisher({
  templateType,
  isLatestTemplateSelected,
  setShowEditVersion,
}: TemplateEditorPublisherProps) {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    state,
    setState,
    getChangedSectionTree,
    getCurrentJsonTemplate,
    getOriginalSectionTree,
  } = useTemplateEditor();
  const [changesDescription, setChangesDescription] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure({
    onClose: () => setChangesDescription(''),
  });
  const { baseTemplate } = state;

  const { userHasPermission } = useContext(UsersContext);
  const canUpdateTemplate = userHasPermission(['update_template'], 'all');

  const setIsEditMode = (isEditMode: TemplateEditorState['isEditMode']) => {
    setState(prev => ({ ...prev, isEditMode: isEditMode }));
  };

  const updateTemplate = useMutation(
    ['template', baseTemplate?.cuid],
    async () => {
      const changedJson = getCurrentJsonTemplate();
      const accessToken = await getAccessTokenSilently();
      return API.UpdateTemplate(
        accessToken,
        baseTemplate!.cuid,
        templateType as 'model_documentation' | 'validation_report',
        changesDescription,
        changedJson,
      );
    },
    {
      onSuccess: updatedTemplate => {
        if (updatedTemplate) {
          toast({
            description: `${baseTemplate.name} version ${updatedTemplate?.current_version?.version_number} created successfully and set as the latest version.`,
            status: 'success',
            isClosable: true,
          });
          navigate(
            `/settings/templates/${updatedTemplate.cuid}/${templateType}/${updatedTemplate.current_version?.cuid}`,
          );
          onClose();
        }
      },
      onError: (error: any) => {
        const errorMessage =
          error.response.status === 400
            ? error.response.data.message.split('\n\n')[0]
            : error.message;
        toast({
          title: "Your new template version didn't save correctly.",
          description: errorMessage,
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  if (!canUpdateTemplate) {
    return null;
  }

  if (!state.isEditMode) {
    return (
      <HStack>
        {canUpdateTemplate && (
          <Button
            data-testid="template-editor-edit"
            onClick={() => setShowEditVersion(true)}
            isDisabled={!isLatestTemplateSelected}
            variant={'ghost'}
          >
            Edit Raw
          </Button>
        )}
        {canUpdateTemplate && (
          <Button
            data-testid="template-editor-edit"
            onClick={() => setIsEditMode(true)}
            leftIcon={<Icon as={PencilIcon} boxSize={4} />}
            isDisabled={!isLatestTemplateSelected}
          >
            Edit Outline
          </Button>
        )}
      </HStack>
    );
  }

  return (
    <HStack>
      <Button
        data-testid="template-editor-cancel"
        onClick={() => setIsEditMode(false)}
        variant="ghost"
      >
        Cancel
      </Button>
      {canUpdateTemplate && (
        <Button
          data-testid="template-editor-submit"
          colorScheme="pink"
          onClick={onOpen}
        >
          Create New Version
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="full"
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent bg={'neutral.50'} margin={16}>
          <ModalHeader>Create New Template Version</ModalHeader>
          <ModalBody>
            <HStack alignItems="normal">
              <VStack flex={3} alignItems="normal" h={'92vh'}>
                <HStack>
                  <Stack flex={1}>
                    <Label>Version</Label>
                    <Input
                      readOnly
                      value={`${baseTemplate.current_version?.version_number} (Latest)`}
                    />
                  </Stack>
                  <Stack flex={1}>
                    <Label>Version</Label>
                    <Input
                      readOnly
                      value={`${
                        baseTemplate.current_version?.version_number! + 1
                      } (Draft)`}
                    />
                  </Stack>
                </HStack>
                <Box
                  height={'calc(90vh - 5rem)'}
                  hidden={false}
                  overflow={'hidden'}
                  borderRadius={'lg'}
                  border={'1px solid'}
                  borderColor={'neutral.200'}
                >
                  <DiffEditor
                    width={'100%'}
                    original={stringify(getOriginalSectionTree())}
                    modified={stringify(getChangedSectionTree())}
                    language="yaml"
                    theme="vs-dark"
                    options={diffOptions}
                  />
                </Box>
                <VStack flex={1} justifyContent="space-between" w={'full'}>
                  <Stack w={'full'}>
                    <Text fontWeight={'bold'}> Create new version </Text>
                    <Spacer h={8} />
                    <Text>
                      Your changes will be saved as a new version of the{' '}
                      <b>{` ${baseTemplate.name} `}</b> template and can be
                      found in the template library.
                    </Text>
                    <Spacer h={8} />
                    <Alert status="info">
                      <HStack align={'flex-start'}>
                        <AlertIcon />
                        <Stack>
                          <AlertDescription>
                            Models that are currently using other versions of
                            this template will not be affected.
                          </AlertDescription>
                        </Stack>
                      </HStack>
                    </Alert>
                    <Spacer />
                    <FormControl>
                      <Label>VERSION NOTES</Label>
                      <Textarea
                        value={changesDescription}
                        onChange={e => setChangesDescription(e.target.value)}
                        placeholder="Add notes about this version to continue"
                      />
                    </FormControl>
                  </Stack>
                  <HStack justifyContent="space-between" w="full">
                    <Button onClick={onClose} variant={'ghost'}>
                      Cancel
                    </Button>
                    {canUpdateTemplate && (
                      <Button
                        onClick={() => updateTemplate.mutate()}
                        isDisabled={
                          !changesDescription.length || updateTemplate.isLoading
                        }
                        isLoading={updateTemplate.isLoading}
                      >
                        Save
                      </Button>
                    )}
                  </HStack>
                </VStack>
              </VStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
}
