import { Box, useColorModeValue } from '@chakra-ui/react';
import { TSummaryItem } from '../../../api/API';
import { DataTable } from '../../DataTable';
import { ContentPageH2 } from '../../Layout';

/**
 * Assume this shape of data:
 * [{"col1": "val1", "col2": "val2"}, {"col1": "val3", "col2": "val4"}"}]
 */
const ResultSummaryTable = ({ tableData }: any) => {
  // The table headers can be extracted from the keys of the first row
  const firstRow = tableData[0];
  const headers = Object.keys(firstRow);
  const columns = headers.map(header => ({
    Header: header,
    accessor: (row: any) => {
      const value = row[header];
      // This is a catch-all for malformed data. We should never have a
      // non-string value in a table cell.
      if (typeof value === 'object') {
        return JSON.stringify(value);
      }
      return String(value);
    },
  }));

  return (
    <Box bg={useColorModeValue('white', 'black')}>
      <DataTable data={tableData} columns={columns} />
    </Box>
  );
};

const ResultSummary = ({ summary }: { summary: TSummaryItem[] }) => {
  // Filter out any summary items that don't have a table property in case
  // the library has not built tables properly.
  const summariesWithTables = summary.filter(
    (summaryItem: TSummaryItem) =>
      summaryItem.data && summaryItem.data.length > 0,
  );

  if (summariesWithTables.length === 0) {
    return null;
  }

  return (
    <Box>
      {summariesWithTables.map((summaryItem: TSummaryItem) => (
        <Box pt={8}>
          {summaryItem.metadata && summaryItem.metadata.title && (
            <ContentPageH2 size="sm" mb={4}>
              {summaryItem.metadata.title}
            </ContentPageH2>
          )}
          <ResultSummaryTable tableData={summaryItem.data} />
        </Box>
      ))}
    </Box>
  );
};

export { ResultSummary, ResultSummaryTable };
