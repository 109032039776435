const app_env = (window as any)._env_ || {};

const apiHostMapping = {
  'app.dev.vm.validmind.ai': 'https://api.dev.vm.validmind.ai/api/v1',
  'app.staging.validmind.ai': 'https://api.staging.validmind.ai/api/v1',
  'app.prod.validmind.ai': 'https://api.prod.validmind.ai/api/v1',
  'app.ca1.validmind.ai': 'https://api.ca1.validmind.ai/api/v1',
};

// Follow the window origin for any other case
// host does not have the http/https prefix so we use it
// to check the apiHostMapping
const host = window.location.host;
const origin = window.location.origin;

export const API_URL =
  (apiHostMapping[host as keyof object] as string) ||
  app_env.REACT_APP_API_URL ||
  `${origin}/api/v1`;

export const CONFIG = {
  REACT_APP_API_URL: API_URL,
  REACT_APP_AUTH0_DOMAIN:
    app_env.REACT_APP_AUTH0_DOMAIN || 'login.dev.vm.validmind.ai',
  REACT_APP_AUTH0_CLIENT_ID:
    app_env.REACT_APP_AUTH0_CLIENT_ID || 'iHpItBjLturosPqnrIFT5S7HbX1IeIUS',
  REACT_APP_AUTH0_AUDIENCE:
    app_env.REACT_APP_AUTH0_AUDIENCE || 'https://api.dev.vm.validmind.ai',
  REACT_APP_AUTH0_NAMESPACE:
    app_env.REACT_APP_AUTH0_NAMESPACE || 'https://validmind.ai',
  REACT_APP_HEAP_ENV_ID: app_env.REACT_APP_HEAP_ENV_ID || '3544910167',
  APP_ENV: app_env.APP_ENV || 'development',
  VM_SITE: app_env.VM_SITE || 'localdev',
  SENTRY_RELEASE: app_env.SENTRY_RELEASE || 'dev',
  SDK_PIP_INSTALL_COMMAND:
    app_env.SDK_PIP_INSTALL_COMMAND || 'pip install validmind',
  ENABLE_MONITORING_MOCKUP: app_env.ENABLE_MONITORING_MOCKUP || false,
  REACT_APP_CKEDITOR_LICENSE_KEY:
    app_env.REACT_APP_CKEDITOR_LICENSE_KEY ||
    'RkxoNWdNYTBkVFRhY2t3YjdoY2VlUWsvTitVWnVEc0w4ZW9KcTJENjZkSFJMbGlSeDN0ellOMUhXQT09LU1qQXlOVEEzTVRRPQ==',
  EXPERIMENTAL_GRAMMAR_CHECKER_ENABLED:
    app_env.EXPERIMENTAL_GRAMMAR_CHECKER_ENABLED || true,
  REACT_APP_LD_CLIENT_ID:
    app_env.REACT_APP_LD_CLIENT_ID || '65bd6e8c67730c1092a95048',
  USE_LAUNCHDARKLY: app_env.USE_LAUNCHDARKLY
    ? app_env.USE_LAUNCHDARKLY === 'true'
    : true,
  SPRIG_ENVIRONMENT_ID: app_env.SPRIG_ENVIRONMENT_ID || 'iOcLt06JSdew',
};
