import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  FormControl,
  Input,
  Textarea,
  ModalFooter,
  Button,
  Spacer,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { TDashboard } from '../../../models/report';
import { Label } from '../../Layout';

type AddOrEditDashboardModalProps = {
  type: 'Page' | 'Dashboard';
  isOpen: boolean;
  existingDashboard?: TDashboard;
  onClose: () => void;
  onSave: ({
    id,
    name,
    description,
  }: {
    id?: string;
    name: string;
    description: string;
  }) => Promise<void>;
};

export const AddOrEditDashboardModal = ({
  type,
  isOpen,
  existingDashboard,
  onClose,
  onSave,
}: AddOrEditDashboardModalProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    await onSave({ name, description, id: existingDashboard?.cuid });
    setIsSubmitting(false);
    onClose();
  };

  useEffect(() => {
    if (existingDashboard) {
      setName(existingDashboard.name);
      setDescription(existingDashboard.description || '');
    } else {
      setName('');
      setDescription('');
    }
  }, [existingDashboard]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!!existingDashboard ? 'Edit' : 'Add New'} {type}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={4}>
            <FormControl>
              <Label mb={2}>{`${type} Name`}</Label>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <Label mb={2}>Description</Label>
              <Textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder={`What will this ${type.toLocaleLowerCase()} be about...`}
              ></Textarea>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isDisabled={!name}
            isLoading={isSubmitting}
            onClick={onSubmit}
            variant={'primary'}
          >
            {!!existingDashboard ? 'Save Changes' : `Add New ${type}`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
