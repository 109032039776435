import {
  Box,
  Button,
  Icon,
  Link,
  useColorModeValue,
  VStack,
  Text,
} from '@chakra-ui/react';
import {
  ArrowLeftEndOnRectangleIcon,
  HomeIcon,
  CogIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex } from '@chakra-ui/react';
import ValidMindLogo from '../../ValidMindLogo';
import { Link as RouterLink } from 'react-router-dom';

// Define sidebar items
const SidebarItems = [
  {
    label: 'Home',
    icon: HomeIcon,
    path: '/admin',
  },
  {
    label: 'Organizations',
    icon: UsersIcon,
    path: '/admin/organizations',
  },
  {
    label: 'Organization Defaults',
    icon: CogIcon,
    path: '/admin/organization-defaults',
  },
  {
    label: 'Users',
    icon: UsersIcon,
    path: '/admin/users',
  },
  {
    label: 'Tools',
    icon: UsersIcon,
    path: '/admin/tools',
  },
];

const AdminLayout = ({ children }: any) => {
  const { logout } = useAuth0();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const onLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Flex h="100vh">
      {/* Sidebar */}
      <Box
        w="15%"
        maxW="400px"
        bg={bgColor}
        borderRight="1px"
        borderColor={borderColor}
        py={8}
        position="fixed"
        h="full"
        display="flex"
        flexDirection="column"
      >
        <Box px={4} mb={8}>
          <ValidMindLogo />
        </Box>

        <VStack spacing={2} align="stretch" flex="1">
          {SidebarItems.map(item => (
            <Link
              key={item.path}
              as={RouterLink}
              to={item.path}
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              _focus={{ textDecoration: 'none' }}
            >
              <Flex
                align="center"
                p={3}
                mx={4}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={
                  location.pathname === item.path ? 'brand.base' : 'transparent'
                }
                color={location.pathname === item.path ? 'white' : 'gray.700'}
                _hover={{
                  bg:
                    location.pathname === item.path
                      ? 'brand.500'
                      : 'neutral.100',
                }}
              >
                <Icon
                  as={item.icon}
                  mr={4}
                  w={4}
                  h={4}
                  color={
                    location.pathname === item.path ? 'white' : 'brand.base'
                  }
                />
                <Text fontSize="sm" fontWeight="medium">
                  {item.label}
                </Text>
              </Flex>
            </Link>
          ))}
        </VStack>

        {/* Logout Button */}
        <Box px={4} mt="auto" mb={4}>
          <Button
            width="full"
            variant="ghost"
            onClick={onLogout}
            leftIcon={<Icon as={ArrowLeftEndOnRectangleIcon} w={5} h={5} />}
            justifyContent="flex-start"
          >
            Logout
          </Button>
        </Box>
      </Box>

      {/* Main Content */}
      <Box ml="15%" w="85%" p={8}>
        {children}
      </Box>
    </Flex>
  );
};

export default AdminLayout;
