import './styles.css';
import { NodeProps, Position, useStore } from 'reactflow';
import {
  Alert,
  AlertIcon,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  VStack,
  Button,
  Box,
  Divider,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import NodeBox from '../../layouts/NodeBox';
import { Cog6ToothIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext, useState } from 'react';
import { UsersContext } from '../../../../../../contexts';
import { TUserWithoutRoles } from '../../../../../../models/user';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { ApprovalNodeType } from '../../types';
import { useOrgRoles } from '../../../../../../hooks/useOrgRoles';
import CustomHandle from '../../handles/CustomHandle';

const zoomSelector = (s: any) => s.transform[2] >= 0.7;

function ApprovalNode(props: NodeProps<ApprovalNodeType['data']>) {
  const { organizationUsers } = useContext(UsersContext);
  const { orgRoles } = useOrgRoles();
  const [users, setUsers] = useState<TUserWithoutRoles[]>([]);
  const { setSelectedNodeId } = useWorkflow();
  const showContent = useStore(zoomSelector);

  type Resolver = {
    [operator: string]: [{ var: string }, string];
  };

  const parseVotersQuery = useCallback(
    (query: Resolver): string => {
      if (
        !query ||
        typeof query !== 'object' ||
        Array.isArray(query) ||
        Object.keys(query).length === 0
      ) {
        return 'Configuration Required';
      }

      const operator = Object.keys(query)[0];
      const [variable, value] = query[operator];

      if (!variable || !variable.var || !value) {
        return 'Configuration Required';
      }

      if (variable.var === 'user.roles') {
        const roleName = orgRoles.find(role => role.cuid === value)?.name;
        return `User Roles: ${roleName}`;
      } else if (variable.var === 'model.custom_fields') {
        return `Model Inventory Field: ${value}`;
      } else {
        return 'Configuration Required';
      }
    },
    [orgRoles],
  );

  if (
    (Object.keys(props.data.state_callbacks.on_enter[0].args.voters) || [])
      .length === 0
  ) {
    return (
      <NodeBox
        title={'APPROVAL'}
        icon={UserGroupIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <HStack px={2} pb={2} w={'full'}>
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            size={'sm'}
            variant={'primary'}
          >
            Configure
          </Button>
        </HStack>
      </NodeBox>
    );
  }

  return (
    <NodeBox
      title={'Approval'}
      icon={UserGroupIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
    >
      <VStack px={showContent ? 4 : 2} py={2}>
        <Box className="home-plate">
          <VStack
            w={'full'}
            alignItems={showContent ? 'start' : 'center'}
            gap={0}
            p={4}
            fontSize={showContent ? 'sm' : '2xl'}
            textAlign={showContent ? 'left' : 'center'}
          >
            <Text fontWeight={showContent ? 'bold' : 'normal'}>
              {props.data.state_callbacks.on_enter[0].args.title}
            </Text>
            {showContent && (
              <>
                <Text maxW={500}>
                  "{props.data.state_callbacks.on_enter[0].args.message}"
                </Text>
                <Divider
                  mt={4}
                  mb={4}
                  borderColor={useColorModeValue('indigo.200', 'indigo.200')}
                />
                <Text>
                  {`
                  ${
                    props.data.state_callbacks.on_enter[0].args.vote_threshold
                  }% of
                  ${parseVotersQuery(
                    props.data.state_callbacks.on_enter[0].args.voters,
                  )}`}
                </Text>
              </>
            )}
          </VStack>
        </Box>
      </VStack>

      <VStack alignItems={'flex-start'} px={2} pb={2}>
        <VStack gap={2} alignItems={'flex-start'} w={'full'}></VStack>
        {(props.data.state_callbacks.on_enter[0].args.on_approved === null ||
          props.data.state_callbacks.on_enter[0].args.on_rejected === null) && (
          <Alert status="warning" size={'xs'}>
            <AlertIcon boxSize="4" />
            <Text fontSize={'xs'}>
              {props.data.state_callbacks.on_enter[0].args.on_approved ===
                null &&
              props.data.state_callbacks.on_enter[0].args.on_rejected === null
                ? 'Missing approval and rejection connection'
                : props.data.state_callbacks.on_enter[0].args.on_approved ===
                  null
                ? 'Missing approval connection'
                : 'Missing rejection connection'}
            </Text>
          </Alert>
        )}
      </VStack>
      <CustomHandle type="target" position={Position.Top} style={{ top: -8 }} />

      <CustomHandle
        id="approved"
        type="source"
        position={Position.Bottom}
        style={{
          background: 'var(--chakra-colors-green-300)',
          marginLeft: '20px',
          border: '1px solid var(--chakra-colors-green-600)',
        }}
      />
      <CustomHandle
        id="rejected"
        type="source"
        position={Position.Bottom}
        style={{
          background: 'var(--chakra-colors-red-300)',
          marginLeft: '-20px',
          border: '1px solid var(--chakra-colors-red-600)',
        }}
      />
    </NodeBox>
  );
}

ApprovalNode.type = 'approval';
ApprovalNode.autoRuns = true;

ApprovalNode.getDefaultNode = (): ApprovalNodeType => {
  const uuid = uuidv4();
  return {
    id: `${ApprovalNode.type}_${uuid}`,
    type: ApprovalNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_approvals',
            args: {
              id: uuid,
              allow_restart: true,
              on_approved: null,
              on_rejected: null,
              voters: [],
              vote_threshold: 100,
              auto_close_on_threshold: false,
              auto_close_after_days: 10,
              title: 'Approval',
              message: 'Please approve this action',
            },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        prepare: [],
        conditions: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default ApprovalNode;
