import { Node, NodeProps, Position, useStore } from 'reactflow';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { StartNodeData } from '../../types';
import NodeBox from '../../layouts/NodeBox';
import CustomHandle from '../../handles/CustomHandle';
const zoomSelector = (s: any) => s.transform[2] >= 0.7;
function StartNode(props: NodeProps) {
  const data = props.data as StartNodeData;
  const showNodeDetails = useStore(zoomSelector);
  return (
    <NodeBox rounded={'full'} nodeProps={props}>
      <Box
        bg={useColorModeValue('green.100', 'green.800')}
        rounded={'full'}
        border={'1px solid'}
        borderColor={'green.500'}
        p={8}
      >
        <Text fontWeight={'bold'} fontSize={showNodeDetails ? 'sm' : '2xl'}>
          Start
        </Text>
        <CustomHandle
          type="source"
          position={Position.Bottom}
          style={{ bottom: -6 }}
        />
      </Box>
    </NodeBox>
  );
}

StartNode.type = 'start';
StartNode.autoRuns = true;

StartNode.getDefaultNode = (): Node => {
  return {
    id: 'start',
    type: StartNode.type,
    position: { x: 0, y: 0 },
    data: {},
  };
};

export default StartNode;
