import { Route, Routes } from 'react-router-dom';
import { LoadingContainer } from '../components/LoadingContainer';
import Organizations from './pages/Organizations';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import OrganizationDefaults from './pages/OrganizationDefaults';
import Users from './pages/Users';
import Tools from './pages/Tools';
import { Cog6ToothIcon as CogIcon } from '@heroicons/react/24/outline';
import ValidMindLogo from '../components/ValidMindLogo';
import { Auth0PrivateRoute } from '../components/PrivateRoute';
import { Auth0PrivateAdminRoute } from '../components/PrivateRoute';
import { CONFIG } from '../config';

function AdminApp() {
  const {
    isLoading: isUserLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
  } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Redirect to Auth0 login if not authenticated
  useEffect(() => {
    if (!isUserLoading && isAuthenticated) {
      const roles = user?.[`${CONFIG.REACT_APP_AUTH0_NAMESPACE}/roles`] || [];
      const hasAdminAccess = roles.includes('admin'); // Adjust the role name as needed

      setIsAuthorized(hasAdminAccess);
    } else if (!isUserLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    }
  }, [isUserLoading, isAuthenticated, loginWithRedirect, user]);

  if (!isAuthenticated) {
    return (
      <LoadingContainer isLoading={true}>
        <></>
      </LoadingContainer>
    );
  }

  // Show a 404 when user is authenticated but not an admin
  if (!isAuthorized) {
    return <NotFound />;
  }

  return (
    <Routes>
      <Route element={<Auth0PrivateAdminRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/organizations" element={<Organizations />} />
        <Route
          path="/organization-defaults"
          element={<OrganizationDefaults />}
        />
        <Route path="/users" element={<Users />} />
        <Route path="/tools" element={<Tools />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AdminApp;
