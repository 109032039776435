import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Flex,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/icons';
import { Label } from '../Layout';
import { ChakraStylesConfig, MultiValue, Select } from 'chakra-react-select';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import {
  convertDateToTimestamp,
  convertTimestampToDate,
  displayFormatedDateAndTime,
} from '../../utils';
import { IFilterProps, THistory } from '../InputInfoModal';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { ModelDocumentTypeEnum } from '../../models/model_document';
import InventoryModelContext from '../../contexts/InventoryModel';

interface TestResultsFilterProps {
  onFilterChange: (filters: any) => void;
  history: THistory[];
  filterToReset: string[];
  setFilterToReset: (filters: string[]) => void;
  contentId: string;
  contentType: ModelDocumentTypeEnum;
  currentSelectedFilters: IFilterProps;
}

const INITIAL_STATE_FILTER_FROM_API = {
  datasets: [],
  date_range: { start_date: null, end_date: null },
  models: [],
  ran_by: [],
};

export default function TestResultsFilter({
  onFilterChange,
  filterToReset,
  setFilterToReset,
  contentId,
  contentType,
  currentSelectedFilters,
}: TestResultsFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [filterDataFromApi, setFilterDataFromApi] = useState<IFilterProps>(
    INITIAL_STATE_FILTER_FROM_API,
  );
  const [selectedModels, setSelectedModels] = useState<
    MultiValue<{ label: string; value: string }>
  >([]);
  const [selectedDataSets, setSelectedDataSets] = useState<
    MultiValue<{ label: string; value: string }>
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<
    MultiValue<{ name: string; cuid: string }>
  >([]);
  const [modelOptions, setModelOptions] = useState<
    MultiValue<{ label: string; value: string }>
  >([]);
  const [datasetOptions, setDatasetOptions] = useState<
    MultiValue<{ label: string; value: string }>
  >([]);
  const [userOptions, setUserOptions] = useState<
    MultiValue<{ label: string; value: string }>
  >([]);

  const { getAccessTokenSilently } = useAuth0();
  const { inventoryModel } = useContext(InventoryModelContext);

  const getFilterData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return await API.GetTestResultsFiltersForInventoryModel(
      inventoryModel?.cuid!,
      token,
      contentId,
      contentType,
    );
  }, [contentId, contentType, inventoryModel, getAccessTokenSilently]);

  const { isFetching: isFetchingFilters } = useQuery(
    ['filters', contentId],
    async () => {
      const results = await getFilterData();
      setFilterDataFromApi(results);
      return results;
    },
    {
      onSuccess: data => {
        // Update filter options without selecting any by default
        updateFilterOptions(data);
      },
    },
  );

  const updateFilterOptions = useCallback((data: IFilterProps) => {
    // Update model options
    const modelOptions = data.models.map(model => ({
      label: model,
      value: model,
    }));
    setModelOptions(modelOptions);

    // Update dataset options
    const datasetOptions = data.datasets.map(dataset => ({
      label: dataset,
      value: dataset,
    }));
    setDatasetOptions(datasetOptions);

    // Update user options
    const userOptions = data.ran_by.map(user => ({
      label: user.name,
      value: user.cuid,
    }));
    setUserOptions(userOptions);

    // Note: We're not setting any selected values here
  }, []);

  const isAnyFilterSelected = () => {
    return (
      dateRange[0] ||
      dateRange[1] ||
      selectedModels.length > 0 ||
      selectedDataSets.length > 0 ||
      selectedUsers.length > 0
    );
  };

  const handleOpen = () => setIsOpen(true);

  const onClearFilters = () => {
    setDateRange([null, null]);
    setSelectedModels([]);
    setSelectedDataSets([]);
    setSelectedUsers([]);
  };

  const handleClose = (clear?: boolean) => {
    setIsOpen(false);

    if (clear && !isAnyFilterSelected()) {
      onClearFilters();
    }
  };

  const handleResetFilters = () => {
    filterToReset.forEach(filter => {
      switch (filter) {
        case 'date_range':
          setDateRange([null, null]);
          break;
        case 'models':
          setSelectedModels([]);
          break;
        case 'datasets':
          setSelectedDataSets([]);
          break;
        case 'ran_by':
          setSelectedUsers([]);
          break;
      }
    });

    setFilterToReset([]);
  };

  const chakraStyles: ChakraStylesConfig = {
    container: (provided: any) => ({
      ...provided,
      minW: '235px',
    }),
    control: (provided: any) => ({
      ...provided,
      minW: '235px',
    }),
  };

  const onApplyFilters = () => {
    handleClose();
    onFilterChange({
      date_range: {
        start_date: dateRange[0]
          ? convertDateToTimestamp(dateRange[0] as Date)
          : null,
        end_date: dateRange[1]
          ? convertDateToTimestamp(dateRange[1] as Date)
          : null,
      },
      models: selectedModels.map(m => m.label),
      datasets: selectedDataSets.map(ds => ds.label),
      ran_by: selectedUsers,
    });
  };

  useEffect(() => {
    handleResetFilters();
  }, []);

  useEffect(() => {
    const updateDateRange = () => {
      const hasDateRangeInfo =
        filterDataFromApi?.date_range?.start_date &&
        filterDataFromApi?.date_range?.end_date;

      if (hasDateRangeInfo) {
        const startDate = convertTimestampToDate(
          filterDataFromApi.date_range.start_date as number,
        );
        const endDate = convertTimestampToDate(
          filterDataFromApi.date_range.end_date as number,
        );
        setDateRange([startDate, endDate]);
      }
    };

    updateDateRange();
  }, [filterDataFromApi, isFetchingFilters]);

  useEffect(() => {
    const updateSelectedModels = () => {
      Object.entries(currentSelectedFilters).forEach(([key, value]) => {
        switch (key) {
          case 'models':
            if (!value.length) return;

            const formattedModels = value.map((model: string) => ({
              label: model,
              value: model,
            }));

            setSelectedModels(formattedModels);
            break;
          case 'datasets':
            if (!value.length) return;

            const formattedDatasets = value.map((dataset: string) => ({
              label: dataset,
              value: dataset,
            }));

            setSelectedDataSets(formattedDatasets);
            break;
          case 'ran_by':
            if (!value.length) return;

            const formattedUsers = value.map(
              (user: { name: string; cuid: string }) => ({
                name: user.name,
                cuid: user.cuid,
              }),
            );

            setSelectedUsers(formattedUsers);
            break;
          case 'date_range':
            setDateRange([
              value.start_date
                ? convertTimestampToDate(value.start_date)
                : null,
              value.end_date ? convertTimestampToDate(value.end_date) : null,
            ]);
            break;
        }
      });
    };

    updateSelectedModels();
  }, []);

  return (
    <Popover
      strategy={'absolute'}
      closeOnBlur={true}
      closeOnEsc={true}
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={() => handleClose(true)}
      placement={'bottom-end'}
    >
      <PopoverTrigger>
        <Button
          leftIcon={<Icon as={FunnelIcon} boxSize={5} />}
          variant={'ghost'}
          size={'sm'}
        >
          Filter
        </Button>
      </PopoverTrigger>
      <PopoverContent w={'68rem'} mr={5} maxW={'90rem'} boxShadow={'xl'}>
        <PopoverArrow />
        <PopoverHeader
          roundedTop={'md'}
          py={4}
          px={10}
          fontSize={'lg'}
          fontWeight={'semibold'}
        >
          Select Your Filters
        </PopoverHeader>
        <PopoverCloseButton
          size={'md'}
          m={2}
          onClick={() => handleClose(true)}
        />
        <PopoverBody py={5} px={10}>
          <HStack spacing={4} align={'stretch'} flexWrap="wrap">
            <Stack>
              <Label>Date Range</Label>
              <HStack gap={4}>
                <DatePicker
                  value={
                    dateRange[0]
                      ? displayFormatedDateAndTime(
                          convertDateToTimestamp(dateRange[0]),
                        )
                      : ''
                  }
                  placeholderText="From"
                  // showTimeSelect
                  isClearable={true}
                  selected={dateRange[0]}
                  onChange={e =>
                    setDateRange(
                      prev => [e, prev[1]] as [Date | null, Date | null],
                    )
                  }
                  customInput={<Input w="235px" />}
                />

                <DatePicker
                  value={
                    dateRange[1]
                      ? displayFormatedDateAndTime(
                          convertDateToTimestamp(dateRange[1]),
                        )
                      : ''
                  }
                  placeholderText="To"
                  // showTimeSelect
                  selected={dateRange[1]}
                  isClearable={true}
                  onChange={e =>
                    setDateRange(
                      prev => [prev[0], e] as [Date | null, Date | null],
                    )
                  }
                  customInput={<Input w="235px" />}
                />
              </HStack>
            </Stack>
            <Stack>
              <Label>Model</Label>
              <Select
                isMulti
                size={'sm'}
                colorScheme="brand"
                menuShouldScrollIntoView={false}
                classNamePrefix="chakra-react-select"
                tagVariant="solid"
                closeMenuOnSelect={false}
                placeholder="Any"
                onChange={e =>
                  setSelectedModels(
                    e as MultiValue<{ label: string; value: string }>,
                  )
                }
                chakraStyles={chakraStyles}
                options={modelOptions}
                value={selectedModels}
              />
            </Stack>

            <Stack>
              <Label>Dataset</Label>
              <Select
                isMulti
                size={'sm'}
                colorScheme="brand"
                menuShouldScrollIntoView={false}
                classNamePrefix="chakra-react-select"
                tagVariant="solid"
                closeMenuOnSelect={false}
                placeholder="Any"
                onChange={e =>
                  setSelectedDataSets(
                    e as MultiValue<{ label: string; value: string }>,
                  )
                }
                chakraStyles={chakraStyles}
                options={datasetOptions}
                value={selectedDataSets}
              />
            </Stack>
            <Stack>
              <Label>Run By</Label>
              <Select
                isMulti
                size={'sm'}
                colorScheme="brand"
                menuShouldScrollIntoView={false}
                classNamePrefix="chakra-react-select"
                tagVariant="solid"
                closeMenuOnSelect={false}
                placeholder="Any"
                onChange={e => {
                  const formattedUsers = (
                    e as Array<{ label: string; value: string }>
                  ).map(user => ({
                    name: user.label,
                    cuid: user.value,
                  }));
                  setSelectedUsers(formattedUsers);
                }}
                chakraStyles={chakraStyles}
                options={userOptions}
                value={selectedUsers.map(user => ({
                  label: user.name,
                  value: user.cuid,
                }))}
              />
            </Stack>
          </HStack>
        </PopoverBody>
        <PopoverFooter p={5} roundedBottom={'md'}>
          <Flex justifyContent={'flex-end'}>
            <Button variant="ghost" mr={3} onClick={onClearFilters}>
              Clear All Filters
            </Button>
            <Spacer />
            <Button onClick={onApplyFilters}>Apply Filters</Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
