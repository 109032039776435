import { Box, Flex, HStack, Icon, useColorModeValue } from '@chakra-ui/react';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { ContentPageTitle } from '../../components/Layout';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import DashboardPage from '../../components/DashboardPage';
import { Copyright } from '../../components/Copyright';

export default () => {
  return (
    <Box
      py={10}
      px={8}
      flex={1}
      w={'full'}
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'neutral.950')}
    >
      <HStack>
        <Flex width={'full'} justify={'space-between'}>
          <HStack gap={5} pl={2} color={'inherit'}>
            <Icon as={ChartBarSquareIcon} boxSize={10} />
            <ContentPageTitle>
              Analytics
              <MoreInfoPopOver
                title="Analytics"
                description="View insights into your model validation efforts, detailing critical findings, risk exposure, and compliance status."
                link="https://docs.validmind.ai/guide/model-validation/view-reports.html"
                placement="right-end"
                iconProps={{
                  ml: 2,
                }}
              />
            </ContentPageTitle>
          </HStack>
        </Flex>
      </HStack>
      <DashboardPage dashboardType="report" />
      <Copyright />
    </Box>
  );
};
