import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  HStack,
  VStack,
  IconButton,
  InputGroup,
  InputRightElement,
  Text,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  Flex,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select'; // Chakra UI wrapper for React Select
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowsUpDownIcon,
  PlusIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/solid';
import { generateStrongPassword } from '../../utils';
import { useState, useEffect } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useUsers, useOrganizations } from '../../hooks/admin';
import { useMutation, useQueryClient } from 'react-query';
import { LoadingContainer } from '../../components/LoadingContainer';
import { displayFormatedDateAndTime } from '../../utils';
import AdminAPI from '../../api/AdminAPI';
import {
  Role,
  Organization,
  PostUserRequest,
  PatchUserRequest,
} from '../../api/AdminAPI';

type SortField = 'email' | 'created_at' | 'updated_at';
type SortOrder = 'asc' | 'desc';

function Users() {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal controls
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { data: users, isLoading, refetch } = useUsers();
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [organizationsWithRoles, setOrganizationsWithRoles] = useState<
    { organization_cuid: string; roles: Role[]; organization_name: string }[]
  >([]);
  const [userPassword, setUserPassword] = useState(generateStrongPassword());
  const [showPassword, setShowPassword] = useState(false);

  const { data: organizations, isLoading: isOrganizationsLoading } =
    useOrganizations();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [sortField, setSortField] = useState<SortField>('created_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [userToEdit, setUserToEdit] = useState<any>(null);

  const createUserMutation = useMutation({
    mutationFn: async (newUser: PostUserRequest) => {
      const accessToken = await getAccessTokenSilently();
      return AdminAPI.PostUser(accessToken, newUser);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users'] });
      toast({
        title: 'User created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      resetModal();
    },
    onError: (error: any) => {
      console.log('Error object:', error);
      console.log('Error response:', error.response?.data);
      toast({
        title: 'Error creating user',
        description: error.response?.data?.message || 'Failed to create user',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: async (patchData: PatchUserRequest) => {
      const accessToken = await getAccessTokenSilently();
      return AdminAPI.PatchUser(accessToken, userToEdit.cuid, patchData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users'] });
      toast({
        title: 'User updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      resetModal();
    },
    onError: (error: any) => {
      toast({
        title: 'Error updating user',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const resetModal = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setUserPassword(generateStrongPassword());
    setOrganizationsWithRoles([]);
    setUserToEdit(null);
    onClose();
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    setCurrentPage(1);
  };

  const getSortIcon = (field: SortField) => {
    if (sortField !== field) {
      return <Icon as={ArrowsUpDownIcon} ml={2} w={4} h={4} />;
    }
    if (sortOrder === 'asc') {
      return <Icon as={ChevronUpIcon} ml={2} w={4} h={4} />;
    }
    return <Icon as={ChevronDownIcon} ml={2} w={4} h={4} />;
  };

  const handleCreate = () => {
    setUserToEdit(null); // Clear edit mode
    setEmail('');
    setFirstName('');
    setLastName('');
    setUserPassword(generateStrongPassword());
    setOrganizationsWithRoles([]); // Clear organizations and roles
    setSelectedOrganization(null);
    setSelectedRoles([]);    
    onOpen(); // Open the modal
  };

  const handleEdit = (user: any) => {
    console.log('Editing user:', user); // Debugging log

    setUserToEdit(user);
    setEmail(user.email);
    setFirstName(user.name);
    setLastName(user.last_name);

    // Map user's organizations to match modal state structure
    const organizationsMapped = user.organizations.map((org: any) => {
      const organization = organizations?.find(o => o.cuid === org.cuid);
      return {
        organization_cuid: organization?.cuid,
        roles: organization?.roles.map((role: any) => ({
          cuid: role.cuid,
          name: role.name,
        })),
        organization_name: organization?.name || 'Unknown',
      };
    });

    if (user.organizations) {
      const org = user.organizations[0];
      const organization = organizations?.find(o => o.cuid === org.cuid);
      setSelectedOrganization(organization || null);
      setSelectedRoles(user.organizations[0].roles || []);
    }

    setOrganizationsWithRoles(organizationsMapped);
    onOpen();
  };

  const handleSubmit = () => {
    if (userToEdit) {
      let payload: PatchUserRequest = {
        name: firstName,
        last_name: lastName,
        password: userPassword,
        email,
        organization: {
          organization_cuid: selectedOrganization?.cuid || '',
          roles: selectedRoles.map(role => role.cuid),
        },
      };
      updateUserMutation.mutate(payload);
    } else {
      let payload: PostUserRequest = {
        name: firstName,
        last_name: lastName,
        password: userPassword,
        email,
        organizations: organizationsWithRoles.map(org => ({
          organization_cuid: org.organization_cuid,
          roles: org.roles.map(role => role.cuid),
        })),
      };
      createUserMutation.mutate(payload);
    }
  };

  const sortedUsers = users
    ? [...users].sort((a, b) => {
        const aValue =
          sortField === 'email' ? a[sortField] : new Date(a[sortField]);
        const bValue =
          sortField === 'email' ? b[sortField] : new Date(b[sortField]);

        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      })
    : [];

  const totalPages = sortedUsers
    ? Math.ceil(sortedUsers.length / itemsPerPage)
    : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentUsers = sortedUsers?.slice(startIndex, endIndex);

  useEffect(() => {
    refetch({});
  }, [users, currentUsers]);

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading>Users</Heading>
        <Button
          variant="primary"
          leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
          onClick={handleCreate}
        >
          Create User
        </Button>
      </Flex>

      {/* Create User Modal */}
      <Modal isOpen={isOpen} onClose={resetModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {userToEdit ? 'Edit User' : 'Create New User'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4} isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter user's email"
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                placeholder="Enter user's first name"
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                placeholder="Enter user's last name"
              />
            </FormControl>
            {!userToEdit && (
              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={userPassword}
                    onChange={e => setUserPassword(e.target.value)}
                    placeholder="Enter password"
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon
                        as={showPassword ? EyeSlashIcon : EyeIcon}
                        w={4}
                        h={4}
                      />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Button
                  size="sm"
                  variant="link"
                  mt={1}
                  onClick={() => setUserPassword(generateStrongPassword())}
                >
                  Generate new password
                </Button>
              </FormControl>
            )}
            <VStack align="stretch" spacing={4}>
              <FormControl>
                <FormLabel>Organization</FormLabel>
                {userToEdit ? (
                  <Text>{selectedOrganization?.name}</Text>
                ) : (
                  <Select
                    isLoading={isOrganizationsLoading}
                    options={organizations?.map(org => ({
                      value: org.cuid,
                      label: org.name,
                      data: org,
                    }))}
                    placeholder="Select an organization"
                    onChange={selectedOption => {
                      setSelectedOrganization(selectedOption?.data || null);
                      setSelectedRoles([]);
                    }}
                  />
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Roles</FormLabel>
                <Select
                  isMulti
                  isDisabled={!selectedOrganization}
                  options={
                    selectedOrganization?.roles
                      .filter(role => role.scope !== 'Model')
                      .map(role => ({
                        value: role.cuid,
                        label: role.name,
                        data: role,
                      })) || []
                  }
                  placeholder="Select roles"
                  value={selectedRoles.map(role => ({
                    value: role.cuid,
                    label: role.name,
                    data: role,
                  }))}
                  onChange={selectedOptions =>
                    setSelectedRoles(selectedOptions.map(option => option.data))
                  }
                  closeMenuOnSelect={false}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={resetModal}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={createUserMutation.isLoading && isLoading}
              isDisabled={
                !email ||
                !firstName ||
                !lastName ||
                !selectedOrganization ||
                organizationsWithRoles.length === 0
              }
            >
              {userToEdit ? 'Update User' : 'Create User'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <LoadingContainer isLoading={isLoading}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>CUID</Th>
              <Th cursor="pointer" onClick={() => handleSort('email')}>
                Email {getSortIcon('email')}
              </Th>
              <Th>Name</Th>
              <Th>Organizations</Th>
              <Th cursor="pointer" onClick={() => handleSort('created_at')}>
                Created At {getSortIcon('created_at')}
              </Th>
              <Th cursor="pointer" onClick={() => handleSort('updated_at')}>
                Updated At {getSortIcon('updated_at')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentUsers?.map(user => (
              <Tr key={user.cuid}>
                <Td>{user.cuid}</Td>
                <Td>{user.email}</Td>
                <Td>
                  {user.last_name}, {user.name}
                </Td>
                <Td>
                  {user.organizations?.map(org => org.name).join(', ') || 'N/A'}
                </Td>
                <Td>{displayFormatedDateAndTime(user.created_at)}</Td>
                <Td>{displayFormatedDateAndTime(user.updated_at)}</Td>
                <Td>
                  <Button size="sm" onClick={() => handleEdit(user)}>
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {totalPages > 1 && (
          <HStack spacing={2} justify="center" mt={6}>
            <Button
              size="sm"
              onClick={() => setCurrentPage(currentPage - 1)}
              isDisabled={currentPage === 1}
            >
              Previous
            </Button>
            <Text>
              Page {currentPage} of {totalPages}
            </Text>
            <Button
              size="sm"
              onClick={() => setCurrentPage(currentPage + 1)}
              isDisabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        )}
      </LoadingContainer>
    </Box>
  );
}

export default Users;
